import React from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Column,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import Checkbox from "@material-ui/core/Checkbox";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import AdminUserListTablePaginationActions from "./AdminUserListTablePaginationActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MailIcon from "@material-ui/icons/Mail";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import AdminAddUserDialog from "./AdminAddUserDialog";
import GlobalFilter from "./GlobalFilter";
import {
  useAllUsersQuery,
  useDeleteEmailTokensMutation,
  useDeleteUsersMutation,
  Users,
  useSendEmailTokensMutation,
} from "../../../generated/graphql";
import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { notEmpty } from "../../../utils/arrayUtils";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef<HTMLButtonElement>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function SelectColumnFilter({ column: { filterValue, setFilter } }: any) {
  return (
    <select
      value={filterValue}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Alle</option>
      <option value="yes">Ja</option>
      <option value="no">Nein</option>
    </select>
  );
}

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  fabMail: {
    paddingLeft: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  tableHeadline: {
    display: "flex",
    alignItems: "center",
  },
  tableHeadlineText: {
    marginLeft: theme.spacing(2),
  },
  tableHeadlineRight: {
    flex: 0,
    textAlign: "right",
  },
  menuPaper: {
    border: "1px solid #d3d4d5",
    marginTop: theme.spacing(1),
  },
}));

function AdminUserList() {
  const client = useAdminApolloClient();
  const classes = useStyles();

  const { data } = useAllUsersQuery({ client });
  const users = React.useMemo(() => (data?.users ?? []) as Users[], [
    data?.users,
  ]);

  const [
    actionsAnchorEl,
    setActionsAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const selectFilter = React.useMemo(
    () => (
      rows: Array<Row<Users>>,
      columnIds: Array<any>,
      filterValue: any
    ) => {
      console.log("columnIds", columnIds);
      console.log("filterValue", filterValue);
      if (filterValue === "yes") {
        console.log("rows", rows);
        return rows.filter((row) => row.values[columnIds[0]]);
      } else if (filterValue === "no") {
        return rows.filter((row) => !row.values[columnIds[0]]);
      }
      return rows;
    },
    []
  );

  const columns = React.useMemo<Column<Users>[]>(
    () => [
      {
        Header: "E-Mail",
        accessor: "email",
        disableFilters: false,
      },
      {
        Header: "Erstellt am",
        accessor: "created_at",
        Cell: ({ value }: { value: any }) =>
          new Date(value).toLocaleString("de-DE"),
        disableFilters: false,
      },
      {
        Header: "Token versand am",
        accessor: "email_token_sent_date",
        Cell: ({ value }: { value: any }) =>
          value ? new Date(value).toLocaleString("de-DE") : "Nicht gesendet",

        Filter: SelectColumnFilter,
        filter: selectFilter,
      },
      {
        Header: "Token ausgetauscht am",
        accessor: "token_exchange_date",
        Cell: ({ value }: { value: any }) =>
          value ? new Date(value).toLocaleString("de-DE") : "Nein",

        Filter: SelectColumnFilter,
        filter: selectFilter,
      },
    ],
    [selectFilter]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data: users,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleChangePage = (event: any, newPage: any) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const getUserIds = () => {
    return (
      Object.entries(selectedRowIds)
        // not sure why the id is currently any?
        .map(([index, value]) =>
          value ? (users[Number(index)].id as string) : null
        )
        .filter(notEmpty)
    );
  };

  const [mutationSendEmailTokens] = useSendEmailTokensMutation({ client });
  const [mutationDeleteEmailTokens] = useDeleteEmailTokensMutation({ client });
  const [mutationDeleteUsers] = useDeleteUsersMutation({ client });

  const handleTokenSend = async () => {
    try {
      await confirm({
        description:
          "Sobald der Nutzer sich einen aktiven Token generiert hat, kann dieser dauerhaft genutzt werden und du hast keinen Zugriff mehr auf die Verwendung.",
      });
    } catch (e) {
      return;
    }

    await mutationSendEmailTokens({
      variables: {
        userIds: getUserIds(),
      },
      refetchQueries: ["AllUsers"],
    });
    enqueueSnackbar("Token sind versendet.", { variant: "success" });
    setActionsAnchorEl(null);
  };

  const handleTokenDelete = async () => {
    try {
      await confirm({
        description:
          "Falls der Token bereits umgetauscht wurde, ändert diese Aktion nichts.",
      });
    } catch (e) {
      return;
    }
    await mutationDeleteEmailTokens({
      variables: {
        userIds: getUserIds(),
      },
      refetchQueries: ["AllUsers"],
    });
    enqueueSnackbar("Token wurden gelöscht.", { variant: "info" });
    setActionsAnchorEl(null);
  };

  const handleUserDelete = async () => {
    try {
      await confirm({
        description:
          "Nutzer, die bereits einen Token erhalten haben, können weiterhin abstimmen!",
      });
    } catch (e) {
      return;
    }
    await mutationDeleteUsers({
      variables: {
        userIds: getUserIds(),
      },
      refetchQueries: ["AllUsers"],
    });
    enqueueSnackbar("Die Nutzer wurden gelöscht.", { variant: "info" });
    setActionsAnchorEl(null);
  };

  return (
    <Container maxWidth="lg">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Abstimmungsberechtigte
      </Typography>
      <Paper className={classes.paper}>
        <div className={classes.tableHeadline}>
          <AdminAddUserDialog />
          <Typography
            component="h3"
            variant="h6"
            className={classes.tableHeadlineText}
          >
            E-Mail Übersicht
          </Typography>
          <div>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              disabled={Object.keys(selectedRowIds).length === 0}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setActionsAnchorEl(event.currentTarget);
              }}
              className={classes.margin}
            >
              Aktionen
            </Button>
            <Menu
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id="actions-menu"
              anchorEl={actionsAnchorEl}
              keepMounted
              open={Boolean(actionsAnchorEl)}
              onClose={() => {
                setActionsAnchorEl(null);
              }}
              classes={{ paper: classes.menuPaper }}
            >
              <MenuItem onClick={handleTokenSend}>
                <ListItemIcon>
                  <MailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Token versenden" />
              </MenuItem>
              <MenuItem onClick={handleTokenDelete}>
                <ListItemIcon>
                  <HighlightOffIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Token löschen" />
              </MenuItem>
              <MenuItem onClick={handleUserDelete}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Nutzer löschen" />
              </MenuItem>
            </Menu>
          </div>
          <div style={{ flex: 1 }}></div>
          <div className={classes.tableHeadlineRight}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>
        <div>
          <MaUTable {...getTableProps()} size="small">
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        {...(column.id === "selection"
                          ? column.getHeaderProps()
                          : column.getHeaderProps(
                              column.getSortByToggleProps()
                            ))}
                      >
                        {column.render("Header")}
                        {column.id !== "selection" ? (
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? "desc" : "asc"}
                          />
                        ) : null}
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    100,
                    500,
                    // { label: "Alle", value: users.length },
                  ]}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} von ${
                      count !== -1 ? count : `mehr als ${to}`
                    }`
                  }
                  labelRowsPerPage="Einträge pro Seite"
                  colSpan={5}
                  align="center"
                  count={users.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  SelectProps={{
                    inputProps: { "aria-label": "Zeilen pro Seite" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={AdminUserListTablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </MaUTable>
        </div>
      </Paper>
    </Container>
  );
}

export default AdminUserList;
