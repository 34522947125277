import React from "react";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";

import AdminQuestionDetail from "./AdminQuestionDetail";

import AdminQuestionsOverview from "./AdminQuestionsOverview";
import {
  Question_State_Enum,
  useAllQuestionsQuery,
} from "../../../generated/graphql";
import AdminQuestionActive from "./AdminQuestionActive";
import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

function AdminQuestions() {
  const client = useAdminApolloClient();
  const classes = useStyles();

  const { page, questionId } = useParams<{
    page?: "edit" | "new" | "active" | "finished";
    questionId?: string;
  }>();

  const { data: questions } = useAllQuestionsQuery({
    client,
    fetchPolicy: "cache-first",
  });

  const hasActive =
    questions?.questions.find(
      (question) => question.state === Question_State_Enum.Active
    ) !== undefined;

  return (
    <Container maxWidth="lg">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Abstimmungen
      </Typography>
      <Grid container>
        <Grid xs={3} item>
          <Paper className={classes.paper}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/admin/questions/new"
            >
              Neu
            </Button>
            <AdminQuestionsOverview questions={questions?.questions ?? []} />
          </Paper>
        </Grid>
        <Grid xs={9} item>
          <Paper className={classes.paper}>
            {page === "active" && <AdminQuestionActive />}
            {page === "new" && <AdminQuestionDetail type="new" />}
            {page === "edit" && (
              <AdminQuestionDetail
                type="edit"
                questionId={questionId}
                isAnyQuestionActive={hasActive}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default React.memo(AdminQuestions);
