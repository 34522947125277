import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  faq: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
}));

function UserExplanation() {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Typography
        component="h1"
        variant="h5"
        className={classes.headline}
        gutterBottom
      >
        Erklärung
      </Typography>
      <Paper className={classes.paper}>
        <Stepper nonLinear={true} orientation="vertical" activeStep={-1}>
          <Step active={true}>
            <StepLabel>EMail Erhalt</StepLabel>
            <StepContent>
              <Typography>
                Du erhälst eine Email mit deinem persönlichen Link. Mit Hilfe
                dieses Links kannst du deinen <strong>Abstimmungs-Code</strong>{" "}
                generieren.
              </Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel>Abstimmungs-Code generieren</StepLabel>
            <StepContent>
              <Typography>
                Indem du auf den Link in der EMail klickst, kommst du auf eine
                Seite, auf der du dir mit einen Klick auf einen Button den
                Abstimmungs-Code generieren kannst. Dieser wird dir dann
                angezeigt. <strong>Wichtig:</strong> Notiere dir diesen Code
                sehr gut! Du kannst ihn beispielsweise ausdrucken. Dieser Code
                ist dein "Wahlschein" und kann nicht ersetzt werden. Außerdem
                garantiert er deine Anonymität.
              </Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel>Mit Code anmelden</StepLabel>
            <StepContent>
              <Typography>
                Auf dieser Homepage kannst du dich mit deinem Abstimmungs-Code
                anmelden, alternative kannst du dich bereits direkt nach Erhalt
                des Codes anmelden und bleibst im gleichen Browser angemeldet.
              </Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel>Abstimmungen teilnehmen</StepLabel>
            <StepContent>
              <Typography>
                Unter "Abstimmungen" kannst du aktive Abstimmungen sehen und an
                diesen teilnehmen. Deine bereits vergebene Stimme kannst du
                dabei nicht einsehen.
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </Paper>

      <Typography
        component="h1"
        variant="h5"
        className={classes.headline}
        gutterBottom
      >
        Fragen
      </Typography>
      <Box className={classes.faq}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Ist die Abstimmung geheim?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>Ja!</strong> Du tauscht deinen persönlichen EMail Link
              gegen einen komplett unpersönlichen und anonymen Abstimmungs-Code
              aus. Dadurch ist deine Anonymität gewahrt und es kann keiner der
              Administratoren oder Entwicklern nachvollziehen, was du abstimmt
              hast.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Wie kann ich das überprüfen?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Der Quelltext wird veröffentlicht und kann daher frei eingesehen
              und überprüft werden.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}

export default UserExplanation;
