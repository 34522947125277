import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  SvgIconProps,
  Typography,
} from "@material-ui/core";
import Color from "color";
import { Link as RouterLink } from "react-router-dom";

interface CardHomeLinkProps {
  to: string;
  title: string;
  text: string;
  IconComponent: React.ComponentType<SvgIconProps>;
  backgroundColor: string;
  textColor: string;
}

interface CardStyleProps {
  backgroundColor: string;
  color: string;
}

const useCardStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    height: "120px",
    alignItems: "center",
  },
  cardText: {
    flex: 1,
  },
  cardIcon: {
    fontSize: "40px",
  },
  actionCard: ({ backgroundColor, color }: CardStyleProps) => ({
    marginTop: theme.spacing(3),
    backgroundColor,
    color,
    "&:hover": {
      boxShadow: `0 4px 10px 0 ${Color(backgroundColor).darken(0.2).fade(0.5)}`,
    },
  }),
}));

function CardHomeLink({
  to,
  title,
  text,
  IconComponent,
  backgroundColor,
  textColor,
}: CardHomeLinkProps) {
  const classes = useCardStyles({
    backgroundColor,
    color: textColor,
  });
  return (
    <Card className={classes.actionCard}>
      <RouterLink to={to} component={CardActionArea}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardText}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {text}
            </Typography>
          </div>
          <div className={classes.cardIcon}>
            <IconComponent fontSize="inherit" />
          </div>
        </CardContent>
      </RouterLink>
    </Card>
  );
}

export default CardHomeLink;
