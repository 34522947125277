import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AdminHome from "./AdminHome/AdminHome";
import AdminAppBar from "../../components/AdminAppBar/AdminAppBar";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import { useAdminStore } from "../../zustand";
import AdminLogin from "../../components/AdminLogin/AdminLogin";
import AdminUserList from "./AdminUserList/AdminUserList";
import AdminQuestions from "./AdminQuestions/AdminQuestions";
import { TypedTypePolicies } from "../../generated/apollo-helpers";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { AdminApolloClient } from "../../context/AdminApolloClient";
import AdminResults from "./AdminResults/AdminResults";
import AdminWebex from "./AdminWebex/AdminWebex";
import { GRAPHQL_URL } from "../../constants/constants";

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      questions_by_pk(existingData, { args, toReference }) {
        if (args?.id) {
          return toReference({
            __typename: "questions",
            id: args?.id,
          });
        }
        return (
          existingData || toReference({ __typename: "questions", id: args?.id })
        );
      },
    },
  },
};

function AdminIndex() {
  const match = useRouteMatch();
  const adminToken = useAdminStore((state) => state.adminToken);
  const isLoggedIn = adminToken !== null;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const adminClient = React.useMemo(
    () =>
      new ApolloClient({
        connectToDevTools: true,
        uri: GRAPHQL_URL,
        cache: new InMemoryCache({ typePolicies }),
        headers: {
          authorization: "Bearer " + adminToken,
        },
      }),
    [adminToken]
  );

  return (
    <AdminApolloClient.Provider value={adminClient}>
      {isLoggedIn && (
        <AdminDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      )}
      <AdminAppBar setDrawerOpen={setDrawerOpen} />

      {isLoggedIn && (
        <Switch>
          <Route path={`${match.path}/users`}>
            <AdminUserList />
          </Route>
          <Route path={`${match.path}/questions/:page?/:questionId?`}>
            <AdminQuestions />
          </Route>
          <Route path={`${match.path}/results/:questionId?`}>
            <AdminResults />
          </Route>
          <Route path={`${match.path}/webex`}>
            <AdminWebex />
          </Route>
          <Route path={`${match.path}`}>
            <AdminHome />
          </Route>
        </Switch>
      )}
      {!isLoggedIn && <AdminLogin />}
    </AdminApolloClient.Provider>
  );
}

export default AdminIndex;
