import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CardHomeLink from "../../../components/CardHomeLink/CardHomeLink";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  firstBox: {
    fontSize: "18px",
  },
}));

function UserHome() {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Online Abstimmung
      </Typography>
      <Paper className={classes.paper}>
        <Box m={3} className={classes.firstBox}>
          Du befindest dich auf der Seite der geheimen Online Abstimmungen für
          die Jahreshauptversammlung des Berliner Ruder-Clubs.
        </Box>
      </Paper>
      <CardHomeLink
        to="/voting"
        title="Abstimmung"
        text="Nehme an den Abstimmungen teil"
        IconComponent={CheckBoxOutlinedIcon}
        backgroundColor="#5E0B15"
        textColor="#FFFFFF"
      />
      <CardHomeLink
        to="/explanation"
        title="Erklärungen"
        text="Für Fragen rund um den technischen Teil der Abstimmung."
        IconComponent={LiveHelpOutlinedIcon}
        backgroundColor="#A3BAC3"
        textColor="#000000"
      />
    </Container>
  );
}

export default UserHome;
