import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import { Alert } from "@material-ui/lab";
import { useCreateUsersMutation } from "../../../generated/graphql";
import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";

function AdminAddUserDialog() {
  const client = useAdminApolloClient();
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState("");

  const [addUsers] = useCreateUsersMutation({
    client,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = async () => {
    const emails = inputValue.split(",").map((text) => ({ email: text }));
    const regexp = new RegExp(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const validEmails = emails.filter((email) => regexp.test(email.email));
    if (validEmails.length === 0) {
      enqueueSnackbar("Keine gültigen Emails.", { variant: "error" });
      return;
    }
    await addUsers({
      variables: { objects: validEmails },
      refetchQueries: ["AllUsers"],
    });
    setOpen(false);
    enqueueSnackbar("Die Emails wurden hinzugefügt.", { variant: "success" });
  };

  return (
    <div>
      <Tooltip title="Hinzufügen">
        <IconButton aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Abstimmungsberechtigte hinzufügen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte fügen Sie eine Liste von eMail Adressen hinzu.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            label="EMail Adressen (kommagetrennt)"
            fullWidth
            multiline
            rows={4}
            rowsMax={12}
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
              setError("");
            }}
          />
          {error === "email-duplicate" && (
            <Box mt={2}>
              <Alert severity="error">Email bereits vorhanden</Alert>
            </Box>
          )}
          {error === "unknown-error" && (
            <Box mt={2}>
              <Alert severity="error">Unbekannter Fehler</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbruch
          </Button>
          <Button onClick={() => handleAdd()} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminAddUserDialog;
