import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/Assessment";
import HomeIcon from "@material-ui/icons/Home";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PeopleIcon from "@material-ui/icons/People";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function AdminDrawer({
  toggleDrawer,
  drawerOpen,
}: {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  drawerOpen: boolean;
}) {
  const classes = useStyles();

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button component={Link} to="/admin">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem button component={Link} to="/admin/users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Nutzer" />
          </ListItem>

          <ListItem button component={Link} to="/admin/questions">
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText primary="Abstimmungen" />
          </ListItem>

          <ListItem button component={Link} to="/admin/results">
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Ergebnisse" />
          </ListItem>

          <ListItem button component={Link} to="/admin/webex">
            <ListItemIcon>
              <VideoCallIcon />
            </ListItemIcon>
            <ListItemText primary="Webex" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default AdminDrawer;
