import React from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";

import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";
import { useQueryFinishedResultsQuery } from "../../../generated/graphql";
import AdminQuestionResult from "./AdminQuestionResult";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

function AdminResults() {
  const client = useAdminApolloClient();
  const classes = useStyles();

  const { questionId } = useParams<{
    questionId?: string;
  }>();

  const { loading, data } = useQueryFinishedResultsQuery({
    client,
    fetchPolicy: "no-cache", // otherwise the results get destroyed by the denormalized view
  });

  const singleQuestions =
    data?.voting_results.filter(
      (question, index) =>
        data.voting_results.findIndex((quest) => quest.id === question.id) ===
        index
    ) ?? [];

  return (
    <Container maxWidth="lg">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Beendete Abstimmungen
      </Typography>
      <Grid container>
        <Grid xs={3} item>
          <Paper className={classes.paper}>
            <List component="nav" aria-label="Aktive Abstimmung">
              {singleQuestions.map((item) => (
                <ListItem
                  button
                  dense
                  key={item.id}
                  component={Link}
                  to={`/admin/results/${item.id}`}
                >
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid xs={9} item>
          <Paper className={classes.paper}>
            {loading && "Loading"}
            {!loading && !questionId && "Keine Abstimmung ausgewählt"}
            {!loading && questionId && (
              <AdminQuestionResult
                questionId={questionId}
                questionResults={data?.voting_results ?? []}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdminResults;
