import React from "react";
import {
  Button,
  Grid,
  Box,
  InputBase,
  Typography,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import {
  Question_State_Enum,
  useCreateQuestionMutation,
  useSingleQuestionQuery,
  useUpdateQuestionMutation,
  useUpdateQuestionStateMutation,
  Voting_Values,
} from "../../../generated/graphql";
import { gql } from "@apollo/client";
import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
  questionTitle: { ...theme.typography.h6, color: "initial !important" },
  questionQuestion: {
    ...theme.typography.subtitle1,
    color: "initial !important",
  },
  edit: {},
  addIcon: {
    marginLeft: theme.spacing(2),
  },
  answersTitle: {},
}));

type VotingOptions = Partial<Pick<Voting_Values, "id">> &
  Pick<Voting_Values, "label">;

function AdminQuestionDetail({
  type,
  questionId,
  isAnyQuestionActive,
}: {
  type: "new" | "edit";
  questionId?: string;
  isAnyQuestionActive?: boolean;
}) {
  const classes = useStyles();
  const client = useAdminApolloClient();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [canEdit, setCanEdit] = React.useState(type === "new");
  const [titleModified, setTitleModified] = React.useState(false);
  const [titleEdit, setTitleEdit] = React.useState({
    editActive: false,
    value: "Titel der Frage",
  });
  const titleInputRef = React.useRef<HTMLInputElement>(null);

  const [questionModified, setQuestionModified] = React.useState(false);
  const [questionEdit, setQuestionEdit] = React.useState({
    editActive: false,
    value: "Der Inhalt der Frage",
  });
  const questionInputRef = React.useRef<HTMLInputElement>(null);

  const [votingOptions, setVotingOptions] = React.useState<VotingOptions[]>([]);

  const [editVotingOptions, setEditVotingOptions] = React.useState(false);
  const [votingOptionModified, setVotingOptionModified] = React.useState(false);

  const { data: questionData } = useSingleQuestionQuery({
    client,
    variables: { id: questionId },
    skip: type === "new",
    // fetchPolicy: "cache-only",
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      setCanEdit(true);
    },
  });

  React.useEffect(() => {
    // reset most states to initial
    setTitleModified(false);
    setQuestionModified(false);
    setVotingOptionModified(false);

    setTitleEdit((state) => ({
      editActive: false,
      value: questionData?.questions_by_pk?.title ?? state.value,
    }));
    setQuestionEdit((state) => ({
      ...state,
      value: questionData?.questions_by_pk?.question ?? state.value,
    }));

    const votingValuesWithoutTypename = (
      questionData?.questions_by_pk?.voting_values ?? []
    ).map((votingValue) => ({
      ...votingValue,
      __typename: undefined,
    }));
    setVotingOptions(votingValuesWithoutTypename);
  }, [questionData?.questions_by_pk]);

  const [mutationNew] = useCreateQuestionMutation({
    client,
    update(cache, inserted) {
      cache.modify({
        fields: {
          questions(existingQuestions = []) {
            const newQuestionRef = cache.writeFragment({
              data: inserted.data?.insert_questions_one,
              fragment: gql`
                fragment NewQuestion on Questions {
                  id
                  state
                  created_at
                  updated_at
                  title
                  question
                }
              `,
            });
            return [...existingQuestions, newQuestionRef];
          },
        },
      });
    },
  });

  const [mutationEdit] = useUpdateQuestionMutation({ client });
  const [mutationUpdateState] = useUpdateQuestionStateMutation({ client });

  const setTitleValue = (event: any) => {
    setTitleModified(true);
    setTitleEdit((state) => ({ ...state, value: event.target.value }));
  };
  const setTitleEditActive = () => {
    setTitleEdit((state) => ({ ...state, editActive: true }));
    setTimeout(() => {
      titleInputRef.current?.select();
      titleInputRef.current?.focus();
    }, 100);
  };
  const setTitleEditSave = () => {
    setTitleEdit((state) => ({ ...state, editActive: false }));
  };

  const setQuestionValue = (event: any) => {
    setQuestionModified(true);
    setQuestionEdit((state) => ({ ...state, value: event.target.value }));
  };
  const setQuestionEditActive = () => {
    setQuestionEdit((state) => ({ ...state, editActive: true }));
    setTimeout(() => {
      questionInputRef.current?.select();
      questionInputRef.current?.focus();
    }, 100);
  };
  const setQuestionEditSave = () => {
    setQuestionEdit((state) => ({ ...state, editActive: false }));
  };

  const canSave = () => {
    if (questionEdit.editActive || titleEdit.editActive) {
      return false;
    }

    if (votingOptions.length < 2) {
      return false;
    }

    if (type === "new") {
      return questionModified && titleModified;
    }
    return questionModified || titleModified || votingOptionModified;
  };

  const saveQuestion = async () => {
    const variables = {
      question: questionEdit.value,
      title: titleEdit.value,
      votingValues: votingOptions,
    };

    if (type === "new") {
      const result = await mutationNew({
        variables,
      });
      if (result.data?.insert_questions_one?.id) {
        history.push(
          `/admin/questions/edit/${result.data?.insert_questions_one?.id}`
        );
      }
      enqueueSnackbar("Neue Frage erstellt", { variant: "success" });
    } else if (type === "edit") {
      await mutationEdit({
        variables: {
          ...variables,
          id: questionId,
          state: Question_State_Enum.Pending,
        },
      });
      enqueueSnackbar("Frage bearbeitet", { variant: "success" });
    }
  };

  const activateQuestion = async () => {
    try {
      await confirm({
        description: "Abstimmung starten?",
      });
    } catch (e) {
      return;
    }
    await mutationUpdateState({
      variables: {
        id: questionId,
        state: Question_State_Enum.Active,
      },
    });
    enqueueSnackbar("Abstimmung gestartet.", { variant: "success" });
  };

  const addField = () => {
    setVotingOptions((current) => [...current, { label: "" }]);
  };

  const removeVotingOption = (index: number) => {
    const newVotingOptions = [...votingOptions];
    newVotingOptions.splice(index, 1);
    setVotingOptions(newVotingOptions);
  };

  const changeOptionValue = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newVotingOptions = [...votingOptions];
    newVotingOptions[index] = {
      ...votingOptions[index],
      label: e.target.value,
    };
    setVotingOptions(newVotingOptions);

    setVotingOptionModified(true);
  };

  return (
    <div>
      <Box mb={2} display="flex">
        {canEdit && (
          <>
            {!titleEdit.editActive && (
              <IconButton
                aria-label="Bearbeiten"
                className={classes.edit}
                onClick={setTitleEditActive}
              >
                <EditIcon />
              </IconButton>
            )}
            {titleEdit.editActive && (
              <IconButton
                aria-label="Speichern"
                className={classes.edit}
                onClick={setTitleEditSave}
              >
                <SaveIcon />
              </IconButton>
            )}
          </>
        )}
        <InputBase
          fullWidth
          disabled={!titleEdit.editActive}
          className={classes.questionTitle}
          value={titleEdit.value}
          onChange={setTitleValue}
          inputProps={{ "aria-label": "Titel" }}
          inputRef={titleInputRef}
        />
      </Box>
      <Box mb={2} display="flex">
        {canEdit && (
          <>
            {!questionEdit.editActive && (
              <IconButton
                aria-label="Bearbeiten"
                className={classes.edit}
                onClick={setQuestionEditActive}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {questionEdit.editActive && (
              <IconButton
                aria-label="Speichern"
                className={classes.edit}
                onClick={setQuestionEditSave}
              >
                <SaveIcon />
              </IconButton>
            )}
          </>
        )}
        <InputBase
          fullWidth
          disabled={!questionEdit.editActive}
          className={classes.questionQuestion}
          value={questionEdit.value}
          onChange={setQuestionValue}
          inputProps={{ "aria-label": "Frage" }}
          inputRef={questionInputRef}
        />
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        {!editVotingOptions && (
          <IconButton
            aria-label="Bearbeiten"
            className={classes.edit}
            onClick={() => setEditVotingOptions(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {editVotingOptions && (
          <IconButton
            aria-label="Bearbeiten"
            className={classes.edit}
            onClick={() => setEditVotingOptions(false)}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.answersTitle}>
          Antwortmöglichkeiten
        </Typography>
        <Button
          variant="contained"
          color="default"
          className={classes.addIcon}
          disabled={!editVotingOptions}
          startIcon={<AddIcon />}
          onClick={addField}
        >
          Hinzufügen
        </Button>
      </Box>
      {votingOptions.map((option, index) => (
        <Box mb={2} display="flex" key={`${option.id}-${index}`}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            disabled={!editVotingOptions}
            value={option.label}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => changeOptionValue(index, e)}
            placeholder="Antwortmöglichkeit eingeben"
            fullWidth
          />
          {editVotingOptions && (
            <IconButton
              aria-label="Bearbeiten"
              className={classes.edit}
              onClick={() => removeVotingOption(index)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      ))}
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!canSave()}
            onClick={() => saveQuestion()}
          >
            Abstimmung speichern
          </Button>
        </Grid>
        <Grid item>
          {!isAnyQuestionActive && type !== "new" && (
            <Button
              variant="contained"
              color="secondary"
              onClick={activateQuestion}
            >
              Abstimmung starten
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminQuestionDetail;
