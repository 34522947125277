import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type SampleInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  accessToken: Scalars['String'];
};

export type SendEmailTokensInput = {
  userIds: Array<Scalars['String']>;
};

export type SendEmailTokensOutput = {
  __typename?: 'SendEmailTokensOutput';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

export type EmailTokenInput = {
  email: Scalars['String'];
  email_token: Scalars['String'];
};

export type EmailTokenOutput = {
  __typename?: 'emailTokenOutput';
  voter_token: Scalars['String'];
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "question_state" */
  delete_question_state?: Maybe<Question_State_Mutation_Response>;
  /** delete single row from the table: "question_state" */
  delete_question_state_by_pk?: Maybe<Question_State>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "voters" */
  delete_voters?: Maybe<Voters_Mutation_Response>;
  /** delete single row from the table: "voters" */
  delete_voters_by_pk?: Maybe<Voters>;
  /** delete data from the table: "voters_online" */
  delete_voters_online?: Maybe<Voters_Online_Mutation_Response>;
  /** delete data from the table: "voters_online_offset" */
  delete_voters_online_offset?: Maybe<Voters_Online_Offset_Mutation_Response>;
  /** delete data from the table: "voting_values" */
  delete_voting_values?: Maybe<Voting_Values_Mutation_Response>;
  /** delete single row from the table: "voting_values" */
  delete_voting_values_by_pk?: Maybe<Voting_Values>;
  /** delete data from the table: "votings" */
  delete_votings?: Maybe<Votings_Mutation_Response>;
  /** delete single row from the table: "votings" */
  delete_votings_by_pk?: Maybe<Votings>;
  /** insert data into the table: "question_state" */
  insert_question_state?: Maybe<Question_State_Mutation_Response>;
  /** insert a single row into the table: "question_state" */
  insert_question_state_one?: Maybe<Question_State>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "voters" */
  insert_voters?: Maybe<Voters_Mutation_Response>;
  /** insert a single row into the table: "voters" */
  insert_voters_one?: Maybe<Voters>;
  /** insert data into the table: "voters_online" */
  insert_voters_online?: Maybe<Voters_Online_Mutation_Response>;
  /** insert data into the table: "voters_online_offset" */
  insert_voters_online_offset?: Maybe<Voters_Online_Offset_Mutation_Response>;
  /** insert a single row into the table: "voters_online_offset" */
  insert_voters_online_offset_one?: Maybe<Voters_Online_Offset>;
  /** insert a single row into the table: "voters_online" */
  insert_voters_online_one?: Maybe<Voters_Online>;
  /** insert data into the table: "voting_values" */
  insert_voting_values?: Maybe<Voting_Values_Mutation_Response>;
  /** insert a single row into the table: "voting_values" */
  insert_voting_values_one?: Maybe<Voting_Values>;
  /** insert data into the table: "votings" */
  insert_votings?: Maybe<Votings_Mutation_Response>;
  /** insert a single row into the table: "votings" */
  insert_votings_one?: Maybe<Votings>;
  /** perform the action: "sendEmailTokens" */
  sendEmailTokens?: Maybe<SendEmailTokensOutput>;
  /** perform the action: "tokenExchange" */
  tokenExchange?: Maybe<EmailTokenOutput>;
  /** update data of the table: "question_state" */
  update_question_state?: Maybe<Question_State_Mutation_Response>;
  /** update single row of the table: "question_state" */
  update_question_state_by_pk?: Maybe<Question_State>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "voters" */
  update_voters?: Maybe<Voters_Mutation_Response>;
  /** update single row of the table: "voters" */
  update_voters_by_pk?: Maybe<Voters>;
  /** update data of the table: "voters_online" */
  update_voters_online?: Maybe<Voters_Online_Mutation_Response>;
  /** update data of the table: "voters_online_offset" */
  update_voters_online_offset?: Maybe<Voters_Online_Offset_Mutation_Response>;
  /** update data of the table: "voting_values" */
  update_voting_values?: Maybe<Voting_Values_Mutation_Response>;
  /** update single row of the table: "voting_values" */
  update_voting_values_by_pk?: Maybe<Voting_Values>;
  /** update data of the table: "votings" */
  update_votings?: Maybe<Votings_Mutation_Response>;
  /** update single row of the table: "votings" */
  update_votings_by_pk?: Maybe<Votings>;
};


/** mutation root */
export type Mutation_RootDelete_Question_StateArgs = {
  where: Question_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Question_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VotersArgs = {
  where: Voters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voters_By_PkArgs = {
  voter_token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Voters_OnlineArgs = {
  where: Voters_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voters_Online_OffsetArgs = {
  where: Voters_Online_Offset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voting_ValuesArgs = {
  where: Voting_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Voting_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VotingsArgs = {
  where: Votings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Votings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Question_StateArgs = {
  objects: Array<Question_State_Insert_Input>;
  on_conflict?: Maybe<Question_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Question_State_OneArgs = {
  object: Question_State_Insert_Input;
  on_conflict?: Maybe<Question_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: Maybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: Maybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VotersArgs = {
  objects: Array<Voters_Insert_Input>;
  on_conflict?: Maybe<Voters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voters_OneArgs = {
  object: Voters_Insert_Input;
  on_conflict?: Maybe<Voters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voters_OnlineArgs = {
  objects: Array<Voters_Online_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Voters_Online_OffsetArgs = {
  objects: Array<Voters_Online_Offset_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Voters_Online_Offset_OneArgs = {
  object: Voters_Online_Offset_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Voters_Online_OneArgs = {
  object: Voters_Online_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Voting_ValuesArgs = {
  objects: Array<Voting_Values_Insert_Input>;
  on_conflict?: Maybe<Voting_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Voting_Values_OneArgs = {
  object: Voting_Values_Insert_Input;
  on_conflict?: Maybe<Voting_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VotingsArgs = {
  objects: Array<Votings_Insert_Input>;
  on_conflict?: Maybe<Votings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Votings_OneArgs = {
  object: Votings_Insert_Input;
  on_conflict?: Maybe<Votings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootSendEmailTokensArgs = {
  arg: SendEmailTokensInput;
};


/** mutation root */
export type Mutation_RootTokenExchangeArgs = {
  input: EmailTokenInput;
};


/** mutation root */
export type Mutation_RootUpdate_Question_StateArgs = {
  _set?: Maybe<Question_State_Set_Input>;
  where: Question_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Question_State_By_PkArgs = {
  _set?: Maybe<Question_State_Set_Input>;
  pk_columns: Question_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _set?: Maybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _set?: Maybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VotersArgs = {
  _inc?: Maybe<Voters_Inc_Input>;
  _set?: Maybe<Voters_Set_Input>;
  where: Voters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voters_By_PkArgs = {
  _inc?: Maybe<Voters_Inc_Input>;
  _set?: Maybe<Voters_Set_Input>;
  pk_columns: Voters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Voters_OnlineArgs = {
  _set?: Maybe<Voters_Online_Set_Input>;
  where: Voters_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voters_Online_OffsetArgs = {
  _set?: Maybe<Voters_Online_Offset_Set_Input>;
  where: Voters_Online_Offset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voting_ValuesArgs = {
  _inc?: Maybe<Voting_Values_Inc_Input>;
  _set?: Maybe<Voting_Values_Set_Input>;
  where: Voting_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Voting_Values_By_PkArgs = {
  _inc?: Maybe<Voting_Values_Inc_Input>;
  _set?: Maybe<Voting_Values_Set_Input>;
  pk_columns: Voting_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VotingsArgs = {
  _set?: Maybe<Votings_Set_Input>;
  where: Votings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Votings_By_PkArgs = {
  _set?: Maybe<Votings_Set_Input>;
  pk_columns: Votings_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "question_state" */
  question_state: Array<Question_State>;
  /** fetch aggregated fields from the table: "question_state" */
  question_state_aggregate: Question_State_Aggregate;
  /** fetch data from the table: "question_state" using primary key columns */
  question_state_by_pk?: Maybe<Question_State>;
  /** fetch data from the table: "questions" */
  questions: Array<Questions>;
  /** fetch aggregated fields from the table: "questions" */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "voters" */
  voters: Array<Voters>;
  /** fetch aggregated fields from the table: "voters" */
  voters_aggregate: Voters_Aggregate;
  /** fetch data from the table: "voters" using primary key columns */
  voters_by_pk?: Maybe<Voters>;
  /** fetch data from the table: "voters_online" */
  voters_online: Array<Voters_Online>;
  /** fetch aggregated fields from the table: "voters_online" */
  voters_online_aggregate: Voters_Online_Aggregate;
  /** fetch data from the table: "voters_online_offset" */
  voters_online_offset: Array<Voters_Online_Offset>;
  /** fetch aggregated fields from the table: "voters_online_offset" */
  voters_online_offset_aggregate: Voters_Online_Offset_Aggregate;
  /** fetch data from the table: "voting_results" */
  voting_results: Array<Voting_Results>;
  /** fetch aggregated fields from the table: "voting_results" */
  voting_results_aggregate: Voting_Results_Aggregate;
  /** fetch data from the table: "voting_values" */
  voting_values: Array<Voting_Values>;
  /** fetch aggregated fields from the table: "voting_values" */
  voting_values_aggregate: Voting_Values_Aggregate;
  /** fetch data from the table: "voting_values" using primary key columns */
  voting_values_by_pk?: Maybe<Voting_Values>;
  /** fetch data from the table: "votings" */
  votings: Array<Votings>;
  /** fetch aggregated fields from the table: "votings" */
  votings_aggregate: Votings_Aggregate;
  /** fetch data from the table: "votings" using primary key columns */
  votings_by_pk?: Maybe<Votings>;
};


/** query root */
export type Query_RootQuestion_StateArgs = {
  distinct_on?: Maybe<Array<Question_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_State_Order_By>>;
  where?: Maybe<Question_State_Bool_Exp>;
};


/** query root */
export type Query_RootQuestion_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Question_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_State_Order_By>>;
  where?: Maybe<Question_State_Bool_Exp>;
};


/** query root */
export type Query_RootQuestion_State_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};


/** query root */
export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};


/** query root */
export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootVotersArgs = {
  distinct_on?: Maybe<Array<Voters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Order_By>>;
  where?: Maybe<Voters_Bool_Exp>;
};


/** query root */
export type Query_RootVoters_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Order_By>>;
  where?: Maybe<Voters_Bool_Exp>;
};


/** query root */
export type Query_RootVoters_By_PkArgs = {
  voter_token: Scalars['String'];
};


/** query root */
export type Query_RootVoters_OnlineArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Order_By>>;
  where?: Maybe<Voters_Online_Bool_Exp>;
};


/** query root */
export type Query_RootVoters_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Order_By>>;
  where?: Maybe<Voters_Online_Bool_Exp>;
};


/** query root */
export type Query_RootVoters_Online_OffsetArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Offset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Offset_Order_By>>;
  where?: Maybe<Voters_Online_Offset_Bool_Exp>;
};


/** query root */
export type Query_RootVoters_Online_Offset_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Offset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Offset_Order_By>>;
  where?: Maybe<Voters_Online_Offset_Bool_Exp>;
};


/** query root */
export type Query_RootVoting_ResultsArgs = {
  distinct_on?: Maybe<Array<Voting_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Results_Order_By>>;
  where?: Maybe<Voting_Results_Bool_Exp>;
};


/** query root */
export type Query_RootVoting_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Voting_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Results_Order_By>>;
  where?: Maybe<Voting_Results_Bool_Exp>;
};


/** query root */
export type Query_RootVoting_ValuesArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** query root */
export type Query_RootVoting_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** query root */
export type Query_RootVoting_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootVotingsArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};


/** query root */
export type Query_RootVotings_AggregateArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};


/** query root */
export type Query_RootVotings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "question_state" */
export type Question_State = {
  __typename?: 'question_state';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "question_state" */
export type Question_State_Aggregate = {
  __typename?: 'question_state_aggregate';
  aggregate?: Maybe<Question_State_Aggregate_Fields>;
  nodes: Array<Question_State>;
};

/** aggregate fields of "question_state" */
export type Question_State_Aggregate_Fields = {
  __typename?: 'question_state_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Question_State_Max_Fields>;
  min?: Maybe<Question_State_Min_Fields>;
};


/** aggregate fields of "question_state" */
export type Question_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Question_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "question_state" */
export type Question_State_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Question_State_Max_Order_By>;
  min?: Maybe<Question_State_Min_Order_By>;
};

/** input type for inserting array relation for remote table "question_state" */
export type Question_State_Arr_Rel_Insert_Input = {
  data: Array<Question_State_Insert_Input>;
  on_conflict?: Maybe<Question_State_On_Conflict>;
};

/** Boolean expression to filter rows from the table "question_state". All fields are combined with a logical 'AND'. */
export type Question_State_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Question_State_Bool_Exp>>>;
  _not?: Maybe<Question_State_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Question_State_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "question_state" */
export enum Question_State_Constraint {
  /** unique or primary key constraint */
  QuestionStatePkey = 'question_state_pkey'
}

export enum Question_State_Enum {
  /** Question is being answered right now by users */
  Active = 'active',
  /** Question got deleted */
  Deleted = 'deleted',
  /** Question got answers and got finished by the admin */
  Finished = 'finished',
  /** Question is not yet asked but ready to be */
  Pending = 'pending'
}

/** expression to compare columns of type question_state_enum. All fields are combined with logical 'AND'. */
export type Question_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Question_State_Enum>;
  _in?: Maybe<Array<Question_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Question_State_Enum>;
  _nin?: Maybe<Array<Question_State_Enum>>;
};

/** input type for inserting data into table "question_state" */
export type Question_State_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Question_State_Max_Fields = {
  __typename?: 'question_state_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "question_state" */
export type Question_State_Max_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Question_State_Min_Fields = {
  __typename?: 'question_state_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "question_state" */
export type Question_State_Min_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "question_state" */
export type Question_State_Mutation_Response = {
  __typename?: 'question_state_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Question_State>;
};

/** input type for inserting object relation for remote table "question_state" */
export type Question_State_Obj_Rel_Insert_Input = {
  data: Question_State_Insert_Input;
  on_conflict?: Maybe<Question_State_On_Conflict>;
};

/** on conflict condition type for table "question_state" */
export type Question_State_On_Conflict = {
  constraint: Question_State_Constraint;
  update_columns: Array<Question_State_Update_Column>;
  where?: Maybe<Question_State_Bool_Exp>;
};

/** ordering options when selecting data from "question_state" */
export type Question_State_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "question_state" */
export type Question_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "question_state" */
export enum Question_State_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "question_state" */
export type Question_State_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "question_state" */
export enum Question_State_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  question: Scalars['String'];
  state: Question_State_Enum;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  voting_values: Array<Voting_Values>;
  /** An aggregated array relationship */
  voting_values_aggregate: Voting_Values_Aggregate;
  /** An array relationship */
  votings: Array<Votings>;
  /** An aggregated array relationship */
  votings_aggregate: Votings_Aggregate;
};


/** columns and relationships of "questions" */
export type QuestionsVoting_ValuesArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsVoting_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsVotingsArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsVotings_AggregateArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Questions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Questions_Max_Order_By>;
  min?: Maybe<Questions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Questions_Bool_Exp>>>;
  _not?: Maybe<Questions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Questions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Question_State_Enum_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vote_finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  vote_started_at?: Maybe<Timestamptz_Comparison_Exp>;
  voting_values?: Maybe<Voting_Values_Bool_Exp>;
  votings?: Maybe<Votings_Bool_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint */
  ConstraintQuestionOneActive = 'constraint_question_one_active',
  /** unique or primary key constraint */
  QuestionsPkey = 'questions_pkey'
}

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  state?: Maybe<Question_State_Enum>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
  voting_values?: Maybe<Voting_Values_Arr_Rel_Insert_Input>;
  votings?: Maybe<Votings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  on_conflict?: Maybe<Questions_On_Conflict>;
};

/** on conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns: Array<Questions_Update_Column>;
  where?: Maybe<Questions_Bool_Exp>;
};

/** ordering options when selecting data from "questions" */
export type Questions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
  voting_values_aggregate?: Maybe<Voting_Values_Aggregate_Order_By>;
  votings_aggregate?: Maybe<Votings_Aggregate_Order_By>;
};

/** primary key columns input for table: "questions" */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoteFinishedAt = 'vote_finished_at',
  /** column name */
  VoteStartedAt = 'vote_started_at'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  state?: Maybe<Question_State_Enum>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoteFinishedAt = 'vote_finished_at',
  /** column name */
  VoteStartedAt = 'vote_started_at'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "question_state" */
  question_state: Array<Question_State>;
  /** fetch aggregated fields from the table: "question_state" */
  question_state_aggregate: Question_State_Aggregate;
  /** fetch data from the table: "question_state" using primary key columns */
  question_state_by_pk?: Maybe<Question_State>;
  /** fetch data from the table: "questions" */
  questions: Array<Questions>;
  /** fetch aggregated fields from the table: "questions" */
  questions_aggregate: Questions_Aggregate;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "voters" */
  voters: Array<Voters>;
  /** fetch aggregated fields from the table: "voters" */
  voters_aggregate: Voters_Aggregate;
  /** fetch data from the table: "voters" using primary key columns */
  voters_by_pk?: Maybe<Voters>;
  /** fetch data from the table: "voters_online" */
  voters_online: Array<Voters_Online>;
  /** fetch aggregated fields from the table: "voters_online" */
  voters_online_aggregate: Voters_Online_Aggregate;
  /** fetch data from the table: "voters_online_offset" */
  voters_online_offset: Array<Voters_Online_Offset>;
  /** fetch aggregated fields from the table: "voters_online_offset" */
  voters_online_offset_aggregate: Voters_Online_Offset_Aggregate;
  /** fetch data from the table: "voting_results" */
  voting_results: Array<Voting_Results>;
  /** fetch aggregated fields from the table: "voting_results" */
  voting_results_aggregate: Voting_Results_Aggregate;
  /** fetch data from the table: "voting_values" */
  voting_values: Array<Voting_Values>;
  /** fetch aggregated fields from the table: "voting_values" */
  voting_values_aggregate: Voting_Values_Aggregate;
  /** fetch data from the table: "voting_values" using primary key columns */
  voting_values_by_pk?: Maybe<Voting_Values>;
  /** fetch data from the table: "votings" */
  votings: Array<Votings>;
  /** fetch aggregated fields from the table: "votings" */
  votings_aggregate: Votings_Aggregate;
  /** fetch data from the table: "votings" using primary key columns */
  votings_by_pk?: Maybe<Votings>;
};


/** subscription root */
export type Subscription_RootQuestion_StateArgs = {
  distinct_on?: Maybe<Array<Question_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_State_Order_By>>;
  where?: Maybe<Question_State_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestion_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Question_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Question_State_Order_By>>;
  where?: Maybe<Question_State_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestion_State_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootQuestionsArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questions_Order_By>>;
  where?: Maybe<Questions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootVotersArgs = {
  distinct_on?: Maybe<Array<Voters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Order_By>>;
  where?: Maybe<Voters_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoters_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Order_By>>;
  where?: Maybe<Voters_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoters_By_PkArgs = {
  voter_token: Scalars['String'];
};


/** subscription root */
export type Subscription_RootVoters_OnlineArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Order_By>>;
  where?: Maybe<Voters_Online_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoters_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Order_By>>;
  where?: Maybe<Voters_Online_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoters_Online_OffsetArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Offset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Offset_Order_By>>;
  where?: Maybe<Voters_Online_Offset_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoters_Online_Offset_AggregateArgs = {
  distinct_on?: Maybe<Array<Voters_Online_Offset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voters_Online_Offset_Order_By>>;
  where?: Maybe<Voters_Online_Offset_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoting_ResultsArgs = {
  distinct_on?: Maybe<Array<Voting_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Results_Order_By>>;
  where?: Maybe<Voting_Results_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoting_Results_AggregateArgs = {
  distinct_on?: Maybe<Array<Voting_Results_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Results_Order_By>>;
  where?: Maybe<Voting_Results_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoting_ValuesArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoting_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Voting_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Voting_Values_Order_By>>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVoting_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootVotingsArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVotings_AggregateArgs = {
  distinct_on?: Maybe<Array<Votings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Votings_Order_By>>;
  where?: Maybe<Votings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVotings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  email_token?: Maybe<Scalars['String']>;
  email_token_sent_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  token_exchange_date?: Maybe<Scalars['timestamptz']>;
  token_exchanged: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_token?: Maybe<String_Comparison_Exp>;
  email_token_sent_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  token_exchange_date?: Maybe<Timestamptz_Comparison_Exp>;
  token_exchanged?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey1 = 'users_pkey1'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_token?: Maybe<Scalars['String']>;
  email_token_sent_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token_exchange_date?: Maybe<Scalars['timestamptz']>;
  token_exchanged?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_token?: Maybe<Scalars['String']>;
  email_token_sent_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token_exchange_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_token?: Maybe<Order_By>;
  email_token_sent_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  token_exchange_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_token?: Maybe<Scalars['String']>;
  email_token_sent_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token_exchange_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_token?: Maybe<Order_By>;
  email_token_sent_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  token_exchange_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_token?: Maybe<Order_By>;
  email_token_sent_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  token_exchange_date?: Maybe<Order_By>;
  token_exchanged?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailToken = 'email_token',
  /** column name */
  EmailTokenSentDate = 'email_token_sent_date',
  /** column name */
  Id = 'id',
  /** column name */
  TokenExchangeDate = 'token_exchange_date',
  /** column name */
  TokenExchanged = 'token_exchanged',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_token?: Maybe<Scalars['String']>;
  email_token_sent_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token_exchange_date?: Maybe<Scalars['timestamptz']>;
  token_exchanged?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailToken = 'email_token',
  /** column name */
  EmailTokenSentDate = 'email_token_sent_date',
  /** column name */
  Id = 'id',
  /** column name */
  TokenExchangeDate = 'token_exchange_date',
  /** column name */
  TokenExchanged = 'token_exchanged',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "voters" */
export type Voters = {
  __typename?: 'voters';
  active: Scalars['Boolean'];
  /** currently not in use for privacy reason */
  last_seen: Scalars['timestamptz'];
  random_id?: Maybe<Scalars['Int']>;
  voter_token: Scalars['String'];
};

/** aggregated selection of "voters" */
export type Voters_Aggregate = {
  __typename?: 'voters_aggregate';
  aggregate?: Maybe<Voters_Aggregate_Fields>;
  nodes: Array<Voters>;
};

/** aggregate fields of "voters" */
export type Voters_Aggregate_Fields = {
  __typename?: 'voters_aggregate_fields';
  avg?: Maybe<Voters_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Voters_Max_Fields>;
  min?: Maybe<Voters_Min_Fields>;
  stddev?: Maybe<Voters_Stddev_Fields>;
  stddev_pop?: Maybe<Voters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Voters_Stddev_Samp_Fields>;
  sum?: Maybe<Voters_Sum_Fields>;
  var_pop?: Maybe<Voters_Var_Pop_Fields>;
  var_samp?: Maybe<Voters_Var_Samp_Fields>;
  variance?: Maybe<Voters_Variance_Fields>;
};


/** aggregate fields of "voters" */
export type Voters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voters_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voters" */
export type Voters_Aggregate_Order_By = {
  avg?: Maybe<Voters_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Voters_Max_Order_By>;
  min?: Maybe<Voters_Min_Order_By>;
  stddev?: Maybe<Voters_Stddev_Order_By>;
  stddev_pop?: Maybe<Voters_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Voters_Stddev_Samp_Order_By>;
  sum?: Maybe<Voters_Sum_Order_By>;
  var_pop?: Maybe<Voters_Var_Pop_Order_By>;
  var_samp?: Maybe<Voters_Var_Samp_Order_By>;
  variance?: Maybe<Voters_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "voters" */
export type Voters_Arr_Rel_Insert_Input = {
  data: Array<Voters_Insert_Input>;
  on_conflict?: Maybe<Voters_On_Conflict>;
};

/** aggregate avg on columns */
export type Voters_Avg_Fields = {
  __typename?: 'voters_avg_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "voters" */
export type Voters_Avg_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "voters". All fields are combined with a logical 'AND'. */
export type Voters_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Voters_Bool_Exp>>>;
  _not?: Maybe<Voters_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Voters_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  random_id?: Maybe<Int_Comparison_Exp>;
  voter_token?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "voters" */
export enum Voters_Constraint {
  /** unique or primary key constraint */
  VotersPkey = 'voters_pkey'
}

/** input type for incrementing integer column in table "voters" */
export type Voters_Inc_Input = {
  random_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "voters" */
export type Voters_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  random_id?: Maybe<Scalars['Int']>;
  voter_token?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Voters_Max_Fields = {
  __typename?: 'voters_max_fields';
  last_seen?: Maybe<Scalars['timestamptz']>;
  random_id?: Maybe<Scalars['Int']>;
  voter_token?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "voters" */
export type Voters_Max_Order_By = {
  last_seen?: Maybe<Order_By>;
  random_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Voters_Min_Fields = {
  __typename?: 'voters_min_fields';
  last_seen?: Maybe<Scalars['timestamptz']>;
  random_id?: Maybe<Scalars['Int']>;
  voter_token?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "voters" */
export type Voters_Min_Order_By = {
  last_seen?: Maybe<Order_By>;
  random_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
};

/** response of any mutation on the table "voters" */
export type Voters_Mutation_Response = {
  __typename?: 'voters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Voters>;
};

/** input type for inserting object relation for remote table "voters" */
export type Voters_Obj_Rel_Insert_Input = {
  data: Voters_Insert_Input;
  on_conflict?: Maybe<Voters_On_Conflict>;
};

/** on conflict condition type for table "voters" */
export type Voters_On_Conflict = {
  constraint: Voters_Constraint;
  update_columns: Array<Voters_Update_Column>;
  where?: Maybe<Voters_Bool_Exp>;
};

/** columns and relationships of "voters_online" */
export type Voters_Online = {
  __typename?: 'voters_online';
  client_id: Scalars['String'];
  last_seen: Scalars['timestamptz'];
};

/** aggregated selection of "voters_online" */
export type Voters_Online_Aggregate = {
  __typename?: 'voters_online_aggregate';
  aggregate?: Maybe<Voters_Online_Aggregate_Fields>;
  nodes: Array<Voters_Online>;
};

/** aggregate fields of "voters_online" */
export type Voters_Online_Aggregate_Fields = {
  __typename?: 'voters_online_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Voters_Online_Max_Fields>;
  min?: Maybe<Voters_Online_Min_Fields>;
};


/** aggregate fields of "voters_online" */
export type Voters_Online_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voters_Online_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voters_online" */
export type Voters_Online_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Voters_Online_Max_Order_By>;
  min?: Maybe<Voters_Online_Min_Order_By>;
};

/** input type for inserting array relation for remote table "voters_online" */
export type Voters_Online_Arr_Rel_Insert_Input = {
  data: Array<Voters_Online_Insert_Input>;
};

/** Boolean expression to filter rows from the table "voters_online". All fields are combined with a logical 'AND'. */
export type Voters_Online_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Voters_Online_Bool_Exp>>>;
  _not?: Maybe<Voters_Online_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Voters_Online_Bool_Exp>>>;
  client_id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "voters_online" */
export type Voters_Online_Insert_Input = {
  client_id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Voters_Online_Max_Fields = {
  __typename?: 'voters_online_max_fields';
  client_id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "voters_online" */
export type Voters_Online_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Voters_Online_Min_Fields = {
  __typename?: 'voters_online_min_fields';
  client_id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "voters_online" */
export type Voters_Online_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
};

/** response of any mutation on the table "voters_online" */
export type Voters_Online_Mutation_Response = {
  __typename?: 'voters_online_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Voters_Online>;
};

/** input type for inserting object relation for remote table "voters_online" */
export type Voters_Online_Obj_Rel_Insert_Input = {
  data: Voters_Online_Insert_Input;
};

/** columns and relationships of "voters_online_offset" */
export type Voters_Online_Offset = {
  __typename?: 'voters_online_offset';
  client_id?: Maybe<Scalars['String']>;
  last_seen_with_offset?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "voters_online_offset" */
export type Voters_Online_Offset_Aggregate = {
  __typename?: 'voters_online_offset_aggregate';
  aggregate?: Maybe<Voters_Online_Offset_Aggregate_Fields>;
  nodes: Array<Voters_Online_Offset>;
};

/** aggregate fields of "voters_online_offset" */
export type Voters_Online_Offset_Aggregate_Fields = {
  __typename?: 'voters_online_offset_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Voters_Online_Offset_Max_Fields>;
  min?: Maybe<Voters_Online_Offset_Min_Fields>;
};


/** aggregate fields of "voters_online_offset" */
export type Voters_Online_Offset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voters_Online_Offset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voters_online_offset" */
export type Voters_Online_Offset_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Voters_Online_Offset_Max_Order_By>;
  min?: Maybe<Voters_Online_Offset_Min_Order_By>;
};

/** input type for inserting array relation for remote table "voters_online_offset" */
export type Voters_Online_Offset_Arr_Rel_Insert_Input = {
  data: Array<Voters_Online_Offset_Insert_Input>;
};

/** Boolean expression to filter rows from the table "voters_online_offset". All fields are combined with a logical 'AND'. */
export type Voters_Online_Offset_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Voters_Online_Offset_Bool_Exp>>>;
  _not?: Maybe<Voters_Online_Offset_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Voters_Online_Offset_Bool_Exp>>>;
  client_id?: Maybe<String_Comparison_Exp>;
  last_seen_with_offset?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "voters_online_offset" */
export type Voters_Online_Offset_Insert_Input = {
  client_id?: Maybe<Scalars['String']>;
  last_seen_with_offset?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Voters_Online_Offset_Max_Fields = {
  __typename?: 'voters_online_offset_max_fields';
  client_id?: Maybe<Scalars['String']>;
  last_seen_with_offset?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "voters_online_offset" */
export type Voters_Online_Offset_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen_with_offset?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Voters_Online_Offset_Min_Fields = {
  __typename?: 'voters_online_offset_min_fields';
  client_id?: Maybe<Scalars['String']>;
  last_seen_with_offset?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "voters_online_offset" */
export type Voters_Online_Offset_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen_with_offset?: Maybe<Order_By>;
};

/** response of any mutation on the table "voters_online_offset" */
export type Voters_Online_Offset_Mutation_Response = {
  __typename?: 'voters_online_offset_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Voters_Online_Offset>;
};

/** input type for inserting object relation for remote table "voters_online_offset" */
export type Voters_Online_Offset_Obj_Rel_Insert_Input = {
  data: Voters_Online_Offset_Insert_Input;
};

/** ordering options when selecting data from "voters_online_offset" */
export type Voters_Online_Offset_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen_with_offset?: Maybe<Order_By>;
};

/** select columns of table "voters_online_offset" */
export enum Voters_Online_Offset_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  LastSeenWithOffset = 'last_seen_with_offset'
}

/** input type for updating data in table "voters_online_offset" */
export type Voters_Online_Offset_Set_Input = {
  client_id?: Maybe<Scalars['String']>;
  last_seen_with_offset?: Maybe<Scalars['timestamptz']>;
};

/** ordering options when selecting data from "voters_online" */
export type Voters_Online_Order_By = {
  client_id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
};

/** select columns of table "voters_online" */
export enum Voters_Online_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  LastSeen = 'last_seen'
}

/** input type for updating data in table "voters_online" */
export type Voters_Online_Set_Input = {
  client_id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** ordering options when selecting data from "voters" */
export type Voters_Order_By = {
  active?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  random_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
};

/** primary key columns input for table: "voters" */
export type Voters_Pk_Columns_Input = {
  voter_token: Scalars['String'];
};

/** select columns of table "voters" */
export enum Voters_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  RandomId = 'random_id',
  /** column name */
  VoterToken = 'voter_token'
}

/** input type for updating data in table "voters" */
export type Voters_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  random_id?: Maybe<Scalars['Int']>;
  voter_token?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Voters_Stddev_Fields = {
  __typename?: 'voters_stddev_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "voters" */
export type Voters_Stddev_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Voters_Stddev_Pop_Fields = {
  __typename?: 'voters_stddev_pop_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "voters" */
export type Voters_Stddev_Pop_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Voters_Stddev_Samp_Fields = {
  __typename?: 'voters_stddev_samp_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "voters" */
export type Voters_Stddev_Samp_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Voters_Sum_Fields = {
  __typename?: 'voters_sum_fields';
  random_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "voters" */
export type Voters_Sum_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** update columns of table "voters" */
export enum Voters_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  RandomId = 'random_id',
  /** column name */
  VoterToken = 'voter_token'
}

/** aggregate var_pop on columns */
export type Voters_Var_Pop_Fields = {
  __typename?: 'voters_var_pop_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "voters" */
export type Voters_Var_Pop_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Voters_Var_Samp_Fields = {
  __typename?: 'voters_var_samp_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "voters" */
export type Voters_Var_Samp_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Voters_Variance_Fields = {
  __typename?: 'voters_variance_fields';
  random_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "voters" */
export type Voters_Variance_Order_By = {
  random_id?: Maybe<Order_By>;
};

/** columns and relationships of "voting_results" */
export type Voting_Results = {
  __typename?: 'voting_results';
  count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_votes?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "voting_results" */
export type Voting_Results_Aggregate = {
  __typename?: 'voting_results_aggregate';
  aggregate?: Maybe<Voting_Results_Aggregate_Fields>;
  nodes: Array<Voting_Results>;
};

/** aggregate fields of "voting_results" */
export type Voting_Results_Aggregate_Fields = {
  __typename?: 'voting_results_aggregate_fields';
  avg?: Maybe<Voting_Results_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Voting_Results_Max_Fields>;
  min?: Maybe<Voting_Results_Min_Fields>;
  stddev?: Maybe<Voting_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Voting_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Voting_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Voting_Results_Sum_Fields>;
  var_pop?: Maybe<Voting_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Voting_Results_Var_Samp_Fields>;
  variance?: Maybe<Voting_Results_Variance_Fields>;
};


/** aggregate fields of "voting_results" */
export type Voting_Results_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voting_Results_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voting_results" */
export type Voting_Results_Aggregate_Order_By = {
  avg?: Maybe<Voting_Results_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Voting_Results_Max_Order_By>;
  min?: Maybe<Voting_Results_Min_Order_By>;
  stddev?: Maybe<Voting_Results_Stddev_Order_By>;
  stddev_pop?: Maybe<Voting_Results_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Voting_Results_Stddev_Samp_Order_By>;
  sum?: Maybe<Voting_Results_Sum_Order_By>;
  var_pop?: Maybe<Voting_Results_Var_Pop_Order_By>;
  var_samp?: Maybe<Voting_Results_Var_Samp_Order_By>;
  variance?: Maybe<Voting_Results_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Voting_Results_Avg_Fields = {
  __typename?: 'voting_results_avg_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "voting_results" */
export type Voting_Results_Avg_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "voting_results". All fields are combined with a logical 'AND'. */
export type Voting_Results_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Voting_Results_Bool_Exp>>>;
  _not?: Maybe<Voting_Results_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Voting_Results_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total_votes?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vote_finished_at?: Maybe<Timestamptz_Comparison_Exp>;
  vote_started_at?: Maybe<Timestamptz_Comparison_Exp>;
  voting_value_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Voting_Results_Max_Fields = {
  __typename?: 'voting_results_max_fields';
  count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_votes?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "voting_results" */
export type Voting_Results_Max_Order_By = {
  count?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Voting_Results_Min_Fields = {
  __typename?: 'voting_results_min_fields';
  count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  total_votes?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vote_finished_at?: Maybe<Scalars['timestamptz']>;
  vote_started_at?: Maybe<Scalars['timestamptz']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "voting_results" */
export type Voting_Results_Min_Order_By = {
  count?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "voting_results" */
export type Voting_Results_Order_By = {
  count?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vote_finished_at?: Maybe<Order_By>;
  vote_started_at?: Maybe<Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** select columns of table "voting_results" */
export enum Voting_Results_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Question = 'question',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TotalVotes = 'total_votes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoteFinishedAt = 'vote_finished_at',
  /** column name */
  VoteStartedAt = 'vote_started_at',
  /** column name */
  VotingValueId = 'voting_value_id'
}

/** aggregate stddev on columns */
export type Voting_Results_Stddev_Fields = {
  __typename?: 'voting_results_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "voting_results" */
export type Voting_Results_Stddev_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Voting_Results_Stddev_Pop_Fields = {
  __typename?: 'voting_results_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "voting_results" */
export type Voting_Results_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Voting_Results_Stddev_Samp_Fields = {
  __typename?: 'voting_results_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "voting_results" */
export type Voting_Results_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Voting_Results_Sum_Fields = {
  __typename?: 'voting_results_sum_fields';
  count?: Maybe<Scalars['bigint']>;
  total_votes?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "voting_results" */
export type Voting_Results_Sum_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Voting_Results_Var_Pop_Fields = {
  __typename?: 'voting_results_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "voting_results" */
export type Voting_Results_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Voting_Results_Var_Samp_Fields = {
  __typename?: 'voting_results_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "voting_results" */
export type Voting_Results_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Voting_Results_Variance_Fields = {
  __typename?: 'voting_results_variance_fields';
  count?: Maybe<Scalars['Float']>;
  total_votes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "voting_results" */
export type Voting_Results_Variance_Order_By = {
  count?: Maybe<Order_By>;
  total_votes?: Maybe<Order_By>;
};

/** columns and relationships of "voting_values" */
export type Voting_Values = {
  __typename?: 'voting_values';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  order: Scalars['Int'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "voting_values" */
export type Voting_Values_Aggregate = {
  __typename?: 'voting_values_aggregate';
  aggregate?: Maybe<Voting_Values_Aggregate_Fields>;
  nodes: Array<Voting_Values>;
};

/** aggregate fields of "voting_values" */
export type Voting_Values_Aggregate_Fields = {
  __typename?: 'voting_values_aggregate_fields';
  avg?: Maybe<Voting_Values_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Voting_Values_Max_Fields>;
  min?: Maybe<Voting_Values_Min_Fields>;
  stddev?: Maybe<Voting_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Voting_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Voting_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Voting_Values_Sum_Fields>;
  var_pop?: Maybe<Voting_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Voting_Values_Var_Samp_Fields>;
  variance?: Maybe<Voting_Values_Variance_Fields>;
};


/** aggregate fields of "voting_values" */
export type Voting_Values_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Voting_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "voting_values" */
export type Voting_Values_Aggregate_Order_By = {
  avg?: Maybe<Voting_Values_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Voting_Values_Max_Order_By>;
  min?: Maybe<Voting_Values_Min_Order_By>;
  stddev?: Maybe<Voting_Values_Stddev_Order_By>;
  stddev_pop?: Maybe<Voting_Values_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Voting_Values_Stddev_Samp_Order_By>;
  sum?: Maybe<Voting_Values_Sum_Order_By>;
  var_pop?: Maybe<Voting_Values_Var_Pop_Order_By>;
  var_samp?: Maybe<Voting_Values_Var_Samp_Order_By>;
  variance?: Maybe<Voting_Values_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "voting_values" */
export type Voting_Values_Arr_Rel_Insert_Input = {
  data: Array<Voting_Values_Insert_Input>;
  on_conflict?: Maybe<Voting_Values_On_Conflict>;
};

/** aggregate avg on columns */
export type Voting_Values_Avg_Fields = {
  __typename?: 'voting_values_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "voting_values" */
export type Voting_Values_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "voting_values". All fields are combined with a logical 'AND'. */
export type Voting_Values_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Voting_Values_Bool_Exp>>>;
  _not?: Maybe<Voting_Values_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Voting_Values_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  question?: Maybe<Questions_Bool_Exp>;
  question_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "voting_values" */
export enum Voting_Values_Constraint {
  /** unique or primary key constraint */
  VotingValuesPkey = 'voting_values_pkey'
}

/** input type for incrementing integer column in table "voting_values" */
export type Voting_Values_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "voting_values" */
export type Voting_Values_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Voting_Values_Max_Fields = {
  __typename?: 'voting_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "voting_values" */
export type Voting_Values_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Voting_Values_Min_Fields = {
  __typename?: 'voting_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "voting_values" */
export type Voting_Values_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "voting_values" */
export type Voting_Values_Mutation_Response = {
  __typename?: 'voting_values_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Voting_Values>;
};

/** input type for inserting object relation for remote table "voting_values" */
export type Voting_Values_Obj_Rel_Insert_Input = {
  data: Voting_Values_Insert_Input;
  on_conflict?: Maybe<Voting_Values_On_Conflict>;
};

/** on conflict condition type for table "voting_values" */
export type Voting_Values_On_Conflict = {
  constraint: Voting_Values_Constraint;
  update_columns: Array<Voting_Values_Update_Column>;
  where?: Maybe<Voting_Values_Bool_Exp>;
};

/** ordering options when selecting data from "voting_values" */
export type Voting_Values_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  question?: Maybe<Questions_Order_By>;
  question_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "voting_values" */
export type Voting_Values_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "voting_values" */
export enum Voting_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "voting_values" */
export type Voting_Values_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Voting_Values_Stddev_Fields = {
  __typename?: 'voting_values_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "voting_values" */
export type Voting_Values_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Voting_Values_Stddev_Pop_Fields = {
  __typename?: 'voting_values_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "voting_values" */
export type Voting_Values_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Voting_Values_Stddev_Samp_Fields = {
  __typename?: 'voting_values_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "voting_values" */
export type Voting_Values_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Voting_Values_Sum_Fields = {
  __typename?: 'voting_values_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "voting_values" */
export type Voting_Values_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "voting_values" */
export enum Voting_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Voting_Values_Var_Pop_Fields = {
  __typename?: 'voting_values_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "voting_values" */
export type Voting_Values_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Voting_Values_Var_Samp_Fields = {
  __typename?: 'voting_values_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "voting_values" */
export type Voting_Values_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Voting_Values_Variance_Fields = {
  __typename?: 'voting_values_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "voting_values" */
export type Voting_Values_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "votings" */
export type Votings = {
  __typename?: 'votings';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  voter_token: Scalars['String'];
  /** An object relationship */
  voting_value: Voting_Values;
  voting_value_id: Scalars['uuid'];
};

/** aggregated selection of "votings" */
export type Votings_Aggregate = {
  __typename?: 'votings_aggregate';
  aggregate?: Maybe<Votings_Aggregate_Fields>;
  nodes: Array<Votings>;
};

/** aggregate fields of "votings" */
export type Votings_Aggregate_Fields = {
  __typename?: 'votings_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Votings_Max_Fields>;
  min?: Maybe<Votings_Min_Fields>;
};


/** aggregate fields of "votings" */
export type Votings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Votings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "votings" */
export type Votings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Votings_Max_Order_By>;
  min?: Maybe<Votings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "votings" */
export type Votings_Arr_Rel_Insert_Input = {
  data: Array<Votings_Insert_Input>;
  on_conflict?: Maybe<Votings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "votings". All fields are combined with a logical 'AND'. */
export type Votings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Votings_Bool_Exp>>>;
  _not?: Maybe<Votings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Votings_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  question?: Maybe<Questions_Bool_Exp>;
  question_id?: Maybe<Uuid_Comparison_Exp>;
  voter_token?: Maybe<String_Comparison_Exp>;
  voting_value?: Maybe<Voting_Values_Bool_Exp>;
  voting_value_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "votings" */
export enum Votings_Constraint {
  /** unique or primary key constraint */
  VotingsPkey = 'votings_pkey',
  /** unique or primary key constraint */
  VotingsQuestionIdVoterTokenKey = 'votings_question_id_voter_token_key'
}

/** input type for inserting data into table "votings" */
export type Votings_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: Maybe<Scalars['uuid']>;
  voter_token?: Maybe<Scalars['String']>;
  voting_value?: Maybe<Voting_Values_Obj_Rel_Insert_Input>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Votings_Max_Fields = {
  __typename?: 'votings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  voter_token?: Maybe<Scalars['String']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "votings" */
export type Votings_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Votings_Min_Fields = {
  __typename?: 'votings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  voter_token?: Maybe<Scalars['String']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "votings" */
export type Votings_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "votings" */
export type Votings_Mutation_Response = {
  __typename?: 'votings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Votings>;
};

/** input type for inserting object relation for remote table "votings" */
export type Votings_Obj_Rel_Insert_Input = {
  data: Votings_Insert_Input;
  on_conflict?: Maybe<Votings_On_Conflict>;
};

/** on conflict condition type for table "votings" */
export type Votings_On_Conflict = {
  constraint: Votings_Constraint;
  update_columns: Array<Votings_Update_Column>;
  where?: Maybe<Votings_Bool_Exp>;
};

/** ordering options when selecting data from "votings" */
export type Votings_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Questions_Order_By>;
  question_id?: Maybe<Order_By>;
  voter_token?: Maybe<Order_By>;
  voting_value?: Maybe<Voting_Values_Order_By>;
  voting_value_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "votings" */
export type Votings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "votings" */
export enum Votings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  VoterToken = 'voter_token',
  /** column name */
  VotingValueId = 'voting_value_id'
}

/** input type for updating data in table "votings" */
export type Votings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  voter_token?: Maybe<Scalars['String']>;
  voting_value_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "votings" */
export enum Votings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  VoterToken = 'voter_token',
  /** column name */
  VotingValueId = 'voting_value_id'
}

export type CoreQuestionFieldsFragment = (
  { __typename?: 'questions' }
  & Pick<Questions, 'created_at' | 'id' | 'question' | 'state' | 'title' | 'updated_at'>
  & { voting_values: Array<(
    { __typename?: 'voting_values' }
    & Pick<Voting_Values, 'id' | 'label' | 'order'>
  )> }
);

export type AllQuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllQuestionsQuery = (
  { __typename?: 'query_root' }
  & { questions: Array<(
    { __typename?: 'questions' }
    & CoreQuestionFieldsFragment
  )> }
);

export type SingleQuestionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SingleQuestionQuery = (
  { __typename?: 'query_root' }
  & { questions_by_pk?: Maybe<(
    { __typename?: 'questions' }
    & CoreQuestionFieldsFragment
  )> }
);

export type ActiveQuestionQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveQuestionQuery = (
  { __typename?: 'query_root' }
  & { questions: Array<(
    { __typename?: 'questions' }
    & CoreQuestionFieldsFragment
  )> }
);

export type CreateQuestionMutationVariables = Exact<{
  question: Scalars['String'];
  title: Scalars['String'];
  votingValues: Array<Voting_Values_Insert_Input> | Voting_Values_Insert_Input;
}>;


export type CreateQuestionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_questions_one?: Maybe<(
    { __typename?: 'questions' }
    & CoreQuestionFieldsFragment
  )> }
);

export type UpdateQuestionStateMutationVariables = Exact<{
  id: Scalars['uuid'];
  state?: Maybe<Question_State_Enum>;
}>;


export type UpdateQuestionStateMutation = (
  { __typename?: 'mutation_root' }
  & { update_questions_by_pk?: Maybe<(
    { __typename?: 'questions' }
    & Pick<Questions, 'created_at' | 'id' | 'question' | 'state' | 'title' | 'updated_at'>
    & { voting_values: Array<(
      { __typename?: 'voting_values' }
      & Pick<Voting_Values, 'id' | 'label' | 'order'>
    )> }
  )> }
);

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['uuid'];
  question: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  state?: Maybe<Question_State_Enum>;
  votingValues: Array<Voting_Values_Insert_Input> | Voting_Values_Insert_Input;
}>;


export type UpdateQuestionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_questions_one?: Maybe<(
    { __typename?: 'questions' }
    & Pick<Questions, 'created_at' | 'id' | 'question' | 'state' | 'title' | 'updated_at'>
    & { voting_values: Array<(
      { __typename?: 'voting_values' }
      & Pick<Voting_Values, 'id' | 'label' | 'order'>
    )> }
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'created_at' | 'email' | 'email_token_sent_date' | 'id' | 'token_exchange_date' | 'token_exchanged'>
  )> }
);

export type CreateUsersMutationVariables = Exact<{
  objects: Array<Users_Insert_Input> | Users_Insert_Input;
}>;


export type CreateUsersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'email' | 'email_token_sent_date' | 'id' | 'token_exchange_date' | 'created_at'>
    )> }
  )> }
);

export type SendEmailTokensMutationVariables = Exact<{
  userIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type SendEmailTokensMutation = (
  { __typename?: 'mutation_root' }
  & { sendEmailTokens?: Maybe<(
    { __typename?: 'SendEmailTokensOutput' }
    & Pick<SendEmailTokensOutput, 'message' | 'success'>
  )> }
);

export type DeleteEmailTokensMutationVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteEmailTokensMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'created_at' | 'email' | 'email_token_sent_date' | 'id' | 'token_exchanged' | 'token_exchange_date' | 'updated_at' | 'email_token'>
    )> }
  )> }
);

export type DeleteUsersMutationVariables = Exact<{
  userIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteUsersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & Pick<Users_Mutation_Response, 'affected_rows'>
  )> }
);

export type QueryFinishedResultsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryFinishedResultsQuery = (
  { __typename?: 'query_root' }
  & { voting_results: Array<(
    { __typename?: 'voting_results' }
    & Pick<Voting_Results, 'count' | 'created_at' | 'id' | 'question' | 'state' | 'title' | 'updated_at' | 'vote_started_at' | 'vote_finished_at' | 'voting_value_id' | 'label' | 'total_votes'>
  )> }
);

export type QueryVotersOnlineQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryVotersOnlineQuery = (
  { __typename?: 'query_root' }
  & { voters_online_offset_aggregate: (
    { __typename?: 'voters_online_offset_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'voters_online_offset_aggregate_fields' }
      & Pick<Voters_Online_Offset_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type QueryQuestionVoteCountQueryVariables = Exact<{
  questionId: Scalars['uuid'];
}>;


export type QueryQuestionVoteCountQuery = (
  { __typename?: 'query_root' }
  & { votings_aggregate: (
    { __typename?: 'votings_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'votings_aggregate_fields' }
      & Pick<Votings_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type SubscribeQuestionsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeQuestionsSubscription = (
  { __typename?: 'subscription_root' }
  & { questions: Array<(
    { __typename?: 'questions' }
    & Pick<Questions, 'id' | 'question' | 'state' | 'title' | 'updated_at'>
    & { votings: Array<(
      { __typename?: 'votings' }
      & Pick<Votings, 'created_at' | 'id' | 'question_id' | 'voting_value_id' | 'voter_token'>
    )>, voting_values: Array<(
      { __typename?: 'voting_values' }
      & Pick<Voting_Values, 'id' | 'label' | 'order'>
    )> }
  )> }
);

export type InsertVoteMutationVariables = Exact<{
  votingValueId: Scalars['uuid'];
  question_id: Scalars['uuid'];
}>;


export type InsertVoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_votings_one?: Maybe<(
    { __typename?: 'votings' }
    & Pick<Votings, 'id' | 'question_id' | 'voting_value_id' | 'voter_token' | 'created_at'>
  )> }
);

export type InsertLastSeenMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;


export type InsertLastSeenMutation = (
  { __typename?: 'mutation_root' }
  & { insert_voters_online?: Maybe<(
    { __typename?: 'voters_online_mutation_response' }
    & Pick<Voters_Online_Mutation_Response, 'affected_rows'>
  )> }
);

export const CoreQuestionFieldsFragmentDoc = gql`
    fragment CoreQuestionFields on questions {
  created_at
  id
  question
  state
  title
  updated_at
  voting_values {
    id
    label
    order
  }
}
    `;
export const AllQuestionsDocument = gql`
    query AllQuestions {
  questions {
    ...CoreQuestionFields
  }
}
    ${CoreQuestionFieldsFragmentDoc}`;

/**
 * __useAllQuestionsQuery__
 *
 * To run a query within a React component, call `useAllQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<AllQuestionsQuery, AllQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllQuestionsQuery, AllQuestionsQueryVariables>(AllQuestionsDocument, options);
      }
export function useAllQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllQuestionsQuery, AllQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllQuestionsQuery, AllQuestionsQueryVariables>(AllQuestionsDocument, options);
        }
export type AllQuestionsQueryHookResult = ReturnType<typeof useAllQuestionsQuery>;
export type AllQuestionsLazyQueryHookResult = ReturnType<typeof useAllQuestionsLazyQuery>;
export type AllQuestionsQueryResult = Apollo.QueryResult<AllQuestionsQuery, AllQuestionsQueryVariables>;
export const SingleQuestionDocument = gql`
    query SingleQuestion($id: uuid!) {
  questions_by_pk(id: $id) {
    ...CoreQuestionFields
  }
}
    ${CoreQuestionFieldsFragmentDoc}`;

/**
 * __useSingleQuestionQuery__
 *
 * To run a query within a React component, call `useSingleQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSingleQuestionQuery(baseOptions: Apollo.QueryHookOptions<SingleQuestionQuery, SingleQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleQuestionQuery, SingleQuestionQueryVariables>(SingleQuestionDocument, options);
      }
export function useSingleQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleQuestionQuery, SingleQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleQuestionQuery, SingleQuestionQueryVariables>(SingleQuestionDocument, options);
        }
export type SingleQuestionQueryHookResult = ReturnType<typeof useSingleQuestionQuery>;
export type SingleQuestionLazyQueryHookResult = ReturnType<typeof useSingleQuestionLazyQuery>;
export type SingleQuestionQueryResult = Apollo.QueryResult<SingleQuestionQuery, SingleQuestionQueryVariables>;
export const ActiveQuestionDocument = gql`
    query ActiveQuestion {
  questions(where: {state: {_eq: active}}) {
    ...CoreQuestionFields
  }
}
    ${CoreQuestionFieldsFragmentDoc}`;

/**
 * __useActiveQuestionQuery__
 *
 * To run a query within a React component, call `useActiveQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveQuestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveQuestionQuery(baseOptions?: Apollo.QueryHookOptions<ActiveQuestionQuery, ActiveQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveQuestionQuery, ActiveQuestionQueryVariables>(ActiveQuestionDocument, options);
      }
export function useActiveQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveQuestionQuery, ActiveQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveQuestionQuery, ActiveQuestionQueryVariables>(ActiveQuestionDocument, options);
        }
export type ActiveQuestionQueryHookResult = ReturnType<typeof useActiveQuestionQuery>;
export type ActiveQuestionLazyQueryHookResult = ReturnType<typeof useActiveQuestionLazyQuery>;
export type ActiveQuestionQueryResult = Apollo.QueryResult<ActiveQuestionQuery, ActiveQuestionQueryVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($question: String!, $title: String!, $votingValues: [voting_values_insert_input!]!) {
  insert_questions_one(
    object: {question: $question, state: pending, title: $title, voting_values: {data: $votingValues}}
  ) {
    ...CoreQuestionFields
  }
}
    ${CoreQuestionFieldsFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *      title: // value for 'title'
 *      votingValues: // value for 'votingValues'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionStateDocument = gql`
    mutation UpdateQuestionState($id: uuid!, $state: question_state_enum) {
  update_questions_by_pk(pk_columns: {id: $id}, _set: {state: $state}) {
    created_at
    id
    question
    state
    title
    updated_at
    voting_values {
      id
      label
      order
    }
  }
}
    `;
export type UpdateQuestionStateMutationFn = Apollo.MutationFunction<UpdateQuestionStateMutation, UpdateQuestionStateMutationVariables>;

/**
 * __useUpdateQuestionStateMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionStateMutation, { data, loading, error }] = useUpdateQuestionStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateQuestionStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionStateMutation, UpdateQuestionStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionStateMutation, UpdateQuestionStateMutationVariables>(UpdateQuestionStateDocument, options);
      }
export type UpdateQuestionStateMutationHookResult = ReturnType<typeof useUpdateQuestionStateMutation>;
export type UpdateQuestionStateMutationResult = Apollo.MutationResult<UpdateQuestionStateMutation>;
export type UpdateQuestionStateMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionStateMutation, UpdateQuestionStateMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($id: uuid!, $question: String!, $title: String, $state: question_state_enum, $votingValues: [voting_values_insert_input!]!) {
  insert_questions_one(
    object: {id: $id, question: $question, title: $title, state: $state, voting_values: {data: $votingValues, on_conflict: {constraint: voting_values_pkey, update_columns: [label, order]}}}
    on_conflict: {constraint: questions_pkey, update_columns: [question, state, title]}
  ) {
    created_at
    id
    question
    state
    title
    updated_at
    voting_values {
      id
      label
      order
    }
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      question: // value for 'question'
 *      title: // value for 'title'
 *      state: // value for 'state'
 *      votingValues: // value for 'votingValues'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const AllUsersDocument = gql`
    query AllUsers {
  users {
    created_at
    email
    email_token_sent_date
    id
    token_exchange_date
    token_exchanged
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const CreateUsersDocument = gql`
    mutation CreateUsers($objects: [users_insert_input!]!) {
  insert_users(objects: $objects) {
    returning {
      email
      email_token_sent_date
      id
      token_exchange_date
      created_at
    }
  }
}
    `;
export type CreateUsersMutationFn = Apollo.MutationFunction<CreateUsersMutation, CreateUsersMutationVariables>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUsersMutation, CreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(CreateUsersDocument, options);
      }
export type CreateUsersMutationHookResult = ReturnType<typeof useCreateUsersMutation>;
export type CreateUsersMutationResult = Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<CreateUsersMutation, CreateUsersMutationVariables>;
export const SendEmailTokensDocument = gql`
    mutation SendEmailTokens($userIds: [String!]!) {
  sendEmailTokens(arg: {userIds: $userIds}) {
    message
    success
  }
}
    `;
export type SendEmailTokensMutationFn = Apollo.MutationFunction<SendEmailTokensMutation, SendEmailTokensMutationVariables>;

/**
 * __useSendEmailTokensMutation__
 *
 * To run a mutation, you first call `useSendEmailTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailTokensMutation, { data, loading, error }] = useSendEmailTokensMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSendEmailTokensMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailTokensMutation, SendEmailTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailTokensMutation, SendEmailTokensMutationVariables>(SendEmailTokensDocument, options);
      }
export type SendEmailTokensMutationHookResult = ReturnType<typeof useSendEmailTokensMutation>;
export type SendEmailTokensMutationResult = Apollo.MutationResult<SendEmailTokensMutation>;
export type SendEmailTokensMutationOptions = Apollo.BaseMutationOptions<SendEmailTokensMutation, SendEmailTokensMutationVariables>;
export const DeleteEmailTokensDocument = gql`
    mutation DeleteEmailTokens($userIds: [uuid!]!) {
  update_users(
    where: {token_exchanged: {_eq: false}, id: {_in: $userIds}}
    _set: {email_token: null, email_token_sent_date: null}
  ) {
    returning {
      created_at
      email
      email_token_sent_date
      id
      token_exchanged
      token_exchange_date
      updated_at
      email_token
    }
  }
}
    `;
export type DeleteEmailTokensMutationFn = Apollo.MutationFunction<DeleteEmailTokensMutation, DeleteEmailTokensMutationVariables>;

/**
 * __useDeleteEmailTokensMutation__
 *
 * To run a mutation, you first call `useDeleteEmailTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailTokensMutation, { data, loading, error }] = useDeleteEmailTokensMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useDeleteEmailTokensMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailTokensMutation, DeleteEmailTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailTokensMutation, DeleteEmailTokensMutationVariables>(DeleteEmailTokensDocument, options);
      }
export type DeleteEmailTokensMutationHookResult = ReturnType<typeof useDeleteEmailTokensMutation>;
export type DeleteEmailTokensMutationResult = Apollo.MutationResult<DeleteEmailTokensMutation>;
export type DeleteEmailTokensMutationOptions = Apollo.BaseMutationOptions<DeleteEmailTokensMutation, DeleteEmailTokensMutationVariables>;
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($userIds: [uuid!]!) {
  delete_users(where: {id: {_in: $userIds}}) {
    affected_rows
  }
}
    `;
export type DeleteUsersMutationFn = Apollo.MutationFunction<DeleteUsersMutation, DeleteUsersMutationVariables>;

/**
 * __useDeleteUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersMutation, { data, loading, error }] = useDeleteUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useDeleteUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersMutation, DeleteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument, options);
      }
export type DeleteUsersMutationHookResult = ReturnType<typeof useDeleteUsersMutation>;
export type DeleteUsersMutationResult = Apollo.MutationResult<DeleteUsersMutation>;
export type DeleteUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUsersMutation, DeleteUsersMutationVariables>;
export const QueryFinishedResultsDocument = gql`
    query QueryFinishedResults {
  voting_results(where: {state: {_eq: "finished"}}, order_by: {updated_at: desc}) {
    count
    created_at
    id
    question
    state
    title
    updated_at
    vote_started_at
    vote_finished_at
    voting_value_id
    label
    total_votes
  }
}
    `;

/**
 * __useQueryFinishedResultsQuery__
 *
 * To run a query within a React component, call `useQueryFinishedResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFinishedResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFinishedResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryFinishedResultsQuery(baseOptions?: Apollo.QueryHookOptions<QueryFinishedResultsQuery, QueryFinishedResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryFinishedResultsQuery, QueryFinishedResultsQueryVariables>(QueryFinishedResultsDocument, options);
      }
export function useQueryFinishedResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryFinishedResultsQuery, QueryFinishedResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryFinishedResultsQuery, QueryFinishedResultsQueryVariables>(QueryFinishedResultsDocument, options);
        }
export type QueryFinishedResultsQueryHookResult = ReturnType<typeof useQueryFinishedResultsQuery>;
export type QueryFinishedResultsLazyQueryHookResult = ReturnType<typeof useQueryFinishedResultsLazyQuery>;
export type QueryFinishedResultsQueryResult = Apollo.QueryResult<QueryFinishedResultsQuery, QueryFinishedResultsQueryVariables>;
export const QueryVotersOnlineDocument = gql`
    query QueryVotersOnline {
  voters_online_offset_aggregate(
    distinct_on: client_id
    where: {last_seen_with_offset: {_gte: "now()"}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useQueryVotersOnlineQuery__
 *
 * To run a query within a React component, call `useQueryVotersOnlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryVotersOnlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryVotersOnlineQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryVotersOnlineQuery(baseOptions?: Apollo.QueryHookOptions<QueryVotersOnlineQuery, QueryVotersOnlineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryVotersOnlineQuery, QueryVotersOnlineQueryVariables>(QueryVotersOnlineDocument, options);
      }
export function useQueryVotersOnlineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryVotersOnlineQuery, QueryVotersOnlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryVotersOnlineQuery, QueryVotersOnlineQueryVariables>(QueryVotersOnlineDocument, options);
        }
export type QueryVotersOnlineQueryHookResult = ReturnType<typeof useQueryVotersOnlineQuery>;
export type QueryVotersOnlineLazyQueryHookResult = ReturnType<typeof useQueryVotersOnlineLazyQuery>;
export type QueryVotersOnlineQueryResult = Apollo.QueryResult<QueryVotersOnlineQuery, QueryVotersOnlineQueryVariables>;
export const QueryQuestionVoteCountDocument = gql`
    query QueryQuestionVoteCount($questionId: uuid!) {
  votings_aggregate(where: {question_id: {_eq: $questionId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useQueryQuestionVoteCountQuery__
 *
 * To run a query within a React component, call `useQueryQuestionVoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuestionVoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuestionVoteCountQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useQueryQuestionVoteCountQuery(baseOptions: Apollo.QueryHookOptions<QueryQuestionVoteCountQuery, QueryQuestionVoteCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryQuestionVoteCountQuery, QueryQuestionVoteCountQueryVariables>(QueryQuestionVoteCountDocument, options);
      }
export function useQueryQuestionVoteCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryQuestionVoteCountQuery, QueryQuestionVoteCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryQuestionVoteCountQuery, QueryQuestionVoteCountQueryVariables>(QueryQuestionVoteCountDocument, options);
        }
export type QueryQuestionVoteCountQueryHookResult = ReturnType<typeof useQueryQuestionVoteCountQuery>;
export type QueryQuestionVoteCountLazyQueryHookResult = ReturnType<typeof useQueryQuestionVoteCountLazyQuery>;
export type QueryQuestionVoteCountQueryResult = Apollo.QueryResult<QueryQuestionVoteCountQuery, QueryQuestionVoteCountQueryVariables>;
export const SubscribeQuestionsDocument = gql`
    subscription SubscribeQuestions {
  questions {
    id
    question
    state
    title
    updated_at
    votings {
      created_at
      id
      question_id
      voting_value_id
      voter_token
    }
    voting_values {
      id
      label
      order
    }
  }
}
    `;

/**
 * __useSubscribeQuestionsSubscription__
 *
 * To run a query within a React component, call `useSubscribeQuestionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeQuestionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeQuestionsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeQuestionsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeQuestionsSubscription, SubscribeQuestionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeQuestionsSubscription, SubscribeQuestionsSubscriptionVariables>(SubscribeQuestionsDocument, options);
      }
export type SubscribeQuestionsSubscriptionHookResult = ReturnType<typeof useSubscribeQuestionsSubscription>;
export type SubscribeQuestionsSubscriptionResult = Apollo.SubscriptionResult<SubscribeQuestionsSubscription>;
export const InsertVoteDocument = gql`
    mutation InsertVote($votingValueId: uuid!, $question_id: uuid!) {
  insert_votings_one(
    object: {question_id: $question_id, voting_value_id: $votingValueId}
  ) {
    id
    question_id
    voting_value_id
    voter_token
    created_at
  }
}
    `;
export type InsertVoteMutationFn = Apollo.MutationFunction<InsertVoteMutation, InsertVoteMutationVariables>;

/**
 * __useInsertVoteMutation__
 *
 * To run a mutation, you first call `useInsertVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVoteMutation, { data, loading, error }] = useInsertVoteMutation({
 *   variables: {
 *      votingValueId: // value for 'votingValueId'
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useInsertVoteMutation(baseOptions?: Apollo.MutationHookOptions<InsertVoteMutation, InsertVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertVoteMutation, InsertVoteMutationVariables>(InsertVoteDocument, options);
      }
export type InsertVoteMutationHookResult = ReturnType<typeof useInsertVoteMutation>;
export type InsertVoteMutationResult = Apollo.MutationResult<InsertVoteMutation>;
export type InsertVoteMutationOptions = Apollo.BaseMutationOptions<InsertVoteMutation, InsertVoteMutationVariables>;
export const InsertLastSeenDocument = gql`
    mutation InsertLastSeen($clientId: String!) {
  insert_voters_online(objects: {client_id: $clientId}) {
    affected_rows
  }
}
    `;
export type InsertLastSeenMutationFn = Apollo.MutationFunction<InsertLastSeenMutation, InsertLastSeenMutationVariables>;

/**
 * __useInsertLastSeenMutation__
 *
 * To run a mutation, you first call `useInsertLastSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLastSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLastSeenMutation, { data, loading, error }] = useInsertLastSeenMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useInsertLastSeenMutation(baseOptions?: Apollo.MutationHookOptions<InsertLastSeenMutation, InsertLastSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLastSeenMutation, InsertLastSeenMutationVariables>(InsertLastSeenDocument, options);
      }
export type InsertLastSeenMutationHookResult = ReturnType<typeof useInsertLastSeenMutation>;
export type InsertLastSeenMutationResult = Apollo.MutationResult<InsertLastSeenMutation>;
export type InsertLastSeenMutationOptions = Apollo.BaseMutationOptions<InsertLastSeenMutation, InsertLastSeenMutationVariables>;