import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { VictoryTheme, VictoryChart, VictoryBar, VictoryAxis } from "victory";
import React from "react";
import { QueryFinishedResultsQuery } from "../../../generated/graphql";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
  questionTitle: { ...theme.typography.h6, color: "initial !important" },
  questionQuestion: {
    ...theme.typography.subtitle1,
    color: "initial !important",
  },
  edit: {},
}));

function AdminQuestionResult({
  questionId,
  questionResults,
}: {
  questionId?: string;
  questionResults: QueryFinishedResultsQuery["voting_results"];
}) {
  const classes = useStyles();

  const selectedQuestion = questionResults.filter(
    (question) => question.id === questionId
  );
  if (selectedQuestion.length === 0) {
    return <div>"Frage nicht gefunden"</div>;
  }

  const resultData = selectedQuestion.map((question) => ({
    valueId: question.voting_value_id || "",
    text: question.label,
    count: question.count,
  }));

  const questionMetadata = selectedQuestion[0];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Box mb={2} display="flex">
          <div className={classes.questionTitle}>{questionMetadata?.title}</div>
        </Box>
        <Box mb={2} display="flex">
          <div className={classes.questionQuestion}>
            {questionMetadata?.question}
          </div>
        </Box>
        <Box mb={2} display="flex">
          <div className={classes.questionQuestion}>
            Anzahl der Stimmen: {questionMetadata?.total_votes}
          </div>
        </Box>
        <Box mb={2} display="flex">
          <div className={classes.questionQuestion}>
            Gestartet:{" "}
            {new Date(questionMetadata?.vote_started_at).toLocaleString(
              "de-DE"
            )}
          </div>
        </Box>
        <Box mb={2} display="flex">
          <div className={classes.questionQuestion}>
            Beendet:{" "}
            {new Date(questionMetadata?.vote_finished_at).toLocaleString(
              "de-DE"
            )}
          </div>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mb={2}>
          <VictoryChart theme={VictoryTheme.material} domainPadding={80}>
            <VictoryAxis
              tickValues={resultData.map((result) => result.valueId)}
              tickFormat={resultData.map((result) => result.text)}
            />
            <VictoryAxis dependentAxis />
            <VictoryBar
              data={resultData}
              x="valueId"
              y="count"
              labels={({ datum }) => datum.count}
              style={{
                data: { fill: "#c43a31" },
                labels: {
                  fontSize: 15,
                  fontWeight: "bold",
                },
              }}
            />
          </VictoryChart>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AdminQuestionResult;
