import React from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useSnackbar } from "notistack";
import { useAdminStore } from "../../zustand";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function AdminAppBar({
  setDrawerOpen,
}: {
  setDrawerOpen: (open: boolean) => void;
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = useAdminStore((state) => state.adminToken) !== null;
  const adminLogout = useAdminStore((state) => state.adminLogout);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {isLoggedIn && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            Admin Bereich
          </Typography>
          {isLoggedIn && (
            <Button
              color="inherit"
              onClick={() => {
                adminLogout();
                enqueueSnackbar("Sie wurden ausgeloggt", { variant: "info" });
              }}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AdminAppBar;
