import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "../constants/constants";
import { useVoterStore } from "../zustand";

export const useVoterLogin = (
  setError: React.Dispatch<React.SetStateAction<boolean>> = () => {}
) => {
  const voterLogin = useVoterStore((state) => state.voterLogin);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const performLogin = async (token: string, redirect: boolean = false) => {
    try {
      const result: any = await fetch(`${API_BASE_URL}/auth/voting-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          voterToken: token,
        }),
      });
      const resultJson = await result.json();
      const { token: jwtToken } = resultJson;
      if (!jwtToken) {
        throw new Error("Login error");
      }
      console.log("jwtToken", jwtToken);
      localStorage.setItem("voterToken", jwtToken);
      voterLogin(jwtToken);
      enqueueSnackbar("Erfolgreich eingeloggt", { variant: "success" });
      if (redirect) {
        history.push(`/voting`);
      }
    } catch (e) {
      if (setError) {
        setError(true);
      } else {
        enqueueSnackbar("Fehler beim Anmelden", { variant: "error" });
      }
    }
  };

  return { performLogin };
};
