import React from "react";
import {
  Button,
  Container,
  Box,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Papa from "papaparse";

import useLocalStorage from "../../../hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
  copyButton: {
    margin: theme.spacing(2),
    width: "50%",
  },
  showUsersButtonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const USER_STATE_COLUMN = "Vorab zugewiesener Sitzungsname";
const USER_STATE_ALLOWED = "Stimmberechtigte";
const USER_EMAIL_COLUMN = "Email";

function AdminWebex() {
  const classes = useStyles();
  const [apiKey, setApiKey] = useLocalStorage<string>("webexApiKey", "");
  const [apiKeyInput, setApiKeyInput] = React.useState(apiKey);

  const [meetings, setMeetings] = React.useState<any[]>([]);
  const [meetingUsers, setMeetingUsers] = React.useState<any[]>([]);
  const [meetingUsersLoading, setMeetingUsersLoading] = React.useState<string>(
    ""
  );
  const [fullUserList, setFullUserList] = useLocalStorage<any[]>(
    "fullUserList",
    []
  );

  React.useEffect(() => {
    const getMeetings = async () => {
      const response = await fetch("https://webexapis.com/v1/meetings", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      });
      const result = await response.json();
      console.log("result", result);
      if (result?.items) {
        setMeetings(result.items);
      }
    };
    getMeetings();
  }, [apiKey]);

  const saveApiKey = () => {
    setApiKey(apiKeyInput);
  };

  const getMeetingUsers = async (id: string) => {
    setMeetingUsers([]);
    setMeetingUsersLoading(id);
    try {
      const response = await fetch(
        `https://webexapis.com/v1/meetingParticipants?meetingId=${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const result = await response.json();
      console.log("result", result);
      if (result?.items) {
        setMeetingUsers(result.items);
      }
    } catch (e) {}
    setMeetingUsersLoading("");
  };

  const emails = meetingUsers.map((user) => user.email).join(",");

  const isAllowedVoter = (user: any) =>
    fullUserList.findIndex(
      (csvUser) =>
        csvUser[USER_EMAIL_COLUMN] === user.email &&
        csvUser[USER_STATE_COLUMN] === USER_STATE_ALLOWED
    ) > -1;

  const allowedEmails = meetingUsers
    .filter((user) => isAllowedVoter(user))
    .map((user) => user.email)
    .join(",");

  const fileReader = new FileReader();
  const handleFileLoaded = () => {
    const parsed = Papa.parse(fileReader.result as string, {
      header: true,
      skipEmptyLines: true,
    });
    if (parsed.data.length > 0) {
      setFullUserList(parsed.data);
    }
  };

  const uploadListOnChange = (e: any) => {
    if (e.target.files[0]) {
      fileReader.onloadend = handleFileLoaded;
      fileReader.readAsText(e.target.files[0], "ISO-8859-1");
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Webex Funktionen
      </Typography>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={4}>
            <Typography
              component="h1"
              variant="h6"
              className={classes.headline}
            >
              API Key
            </Typography>
            <Typography variant="body1">
              Api Key ist zu erhalten unter:{" "}
              <a
                href="https://developer.webex.com/docs/api/getting-started"
                target="_blank"
                rel="noreferrer"
              >
                https://developer.webex.com/docs/api/getting-started
              </a>
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="API Key"
              name="apiKey"
              onChange={(e) => setApiKeyInput(e.target.value)}
              value={apiKeyInput}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={saveApiKey}
            >
              Speichern
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <Typography
              component="h1"
              variant="h6"
              className={classes.headline}
            >
              Teilnehmer Tabelle
            </Typography>
            <Typography variant="body1">
              Mit Hilfe der Teilnehmer Tabelle können nur die stimmberechtigten
              Mitglieder von der aktuellen webex Teilnehmer Liste kopiert
              werden. Die Datei wird nicht auf den Server geladen sondern
              existiert ausschließlich lokal im Browser.
            </Typography>
            <input
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={uploadListOnChange}
              accept=".csv"
            />
            <label htmlFor="contained-button-file">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                component="span"
              >
                Datei hochladen
              </Button>
            </label>
            {fullUserList.length > 0 && (
              <Box mt={2}>
                Aktuell Datei mit {fullUserList.length} Einträgen hochgeladen.
              </Box>
            )}
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography component="h1" variant="h6" className={classes.headline}>
            Meetings
          </Typography>
          <TableContainer component="div">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titel</TableCell>
                  <TableCell align="right">Start</TableCell>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetings.map((meeting) => (
                  <TableRow key={meeting.id}>
                    <TableCell component="th" scope="row">
                      {meeting.title}
                    </TableCell>
                    <TableCell align="right">{meeting.start}</TableCell>
                    <TableCell align="right">{meeting.id}</TableCell>
                    <TableCell align="right">
                      <div className={classes.showUsersButtonWrapper}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={meetingUsersLoading === meeting.id}
                          onClick={() => getMeetingUsers(meeting.id)}
                        >
                          Nutzer anzeigen
                        </Button>
                        {meetingUsersLoading === meeting.id && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={5}>
          <Typography component="h1" variant="h6" className={classes.headline}>
            AKTIVE Nutzer eines Meetings
          </Typography>
          <CopyToClipboard text={allowedEmails}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.copyButton}
            >
              Stimmberechtigte Emails in Zwischenablage kopieren
            </Button>
          </CopyToClipboard>
          <TableContainer component="div">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Teilnahmezeit</TableCell>
                  <TableCell align="right">Stimmberechtigt?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row">
                      {user.displayName}
                    </TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">
                      {user.devices?.[0]?.joinedTime}
                    </TableCell>
                    <TableCell align="right">
                      {isAllowedVoter(user) ? "Ja" : "Nein"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <CopyToClipboard text={emails}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                className={classes.copyButton}
              >
                Alle Emails in Zwischenablage kopieren
              </Button>
            </CopyToClipboard>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default AdminWebex;
