import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import {
  Question_State_Enum,
  useActiveQuestionQuery,
  useQueryQuestionVoteCountQuery,
  useQueryVotersOnlineQuery,
  useUpdateQuestionStateMutation,
} from "../../../generated/graphql";
import { useAdminApolloClient } from "../../../hooks/useAdminApolloClient";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
  },
  questionTitle: { ...theme.typography.h6, color: "initial !important" },
  questionQuestion: {
    ...theme.typography.subtitle1,
    color: "initial !important",
  },
  edit: {},
}));

function AdminQuestionActive() {
  const classes = useStyles();
  const client = useAdminApolloClient();

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const { data: questionData } = useActiveQuestionQuery({ client });
  console.log("questionData", questionData);

  const question = questionData?.questions?.[0];
  const [mutationUpdate] = useUpdateQuestionStateMutation({ client });

  const { data: votersOnline } = useQueryVotersOnlineQuery({
    client,
    pollInterval: 20000,
  });

  const { data: voteCount } = useQueryQuestionVoteCountQuery({
    client,
    variables: {
      questionId: questionData?.questions[0]?.id,
    },
    pollInterval: 20000,
    skip: !questionData?.questions[0]?.id,
  });

  const endQuestion = async () => {
    try {
      await confirm({
        description:
          "Das Beenden der Abstimmung kann nicht rückgängig gemacht werden!",
      });
    } catch (e) {
      return;
    }
    await mutationUpdate({
      variables: {
        id: question?.id,
        state: Question_State_Enum.Finished,
      },
      refetchQueries: ["ActiveQuestion"],
    });
    enqueueSnackbar("Abstimmung beendet.", { variant: "success" });
  };

  if (questionData?.questions.length === 0) {
    return (
      <div>
        <Box mb={2} display="flex">
          <div className={classes.questionTitle}>Keine Abstimmung aktiv</div>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <Box mb={2} display="flex">
        <div className={classes.questionTitle}>{question?.title}</div>
      </Box>
      <Box mb={2} display="flex">
        <div className={classes.questionQuestion}>{question?.question}</div>
      </Box>
      <Box mb={2}>
        <Typography variant="h6">Antwortmöglichkeiten</Typography>
      </Box>
      {question?.voting_values.map((option, index) => (
        <Box mb={2} display="flex" key={option.id}>
          <span style={{ fontWeight: "bold", marginRight: "10px" }}>
            {index + 1}.
          </span>
          {option.label}
        </Box>
      ))}
      <Box mb={2}>
        <Typography variant="h6">Nutzer Online:</Typography>
      </Box>
      <Box mb={3}>
        <strong>
          {votersOnline?.voters_online_offset_aggregate.aggregate?.count}
        </strong>{" "}
        Nutzer online (letzte 30 Sekunden)
      </Box>

      <Box mb={2}>
        <Typography variant="h6">Stimmen:</Typography>
      </Box>
      <Box mb={3}>
        <strong>{voteCount?.votings_aggregate.aggregate?.count}</strong> Stimmen
        abgegeben
      </Box>
      <Box mb={3}>
        <Button variant="contained" color="primary" onClick={endQuestion}>
          Abstimmung beenden
        </Button>
      </Box>
    </div>
  );
}

export default AdminQuestionActive;
