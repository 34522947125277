import create from "zustand";
import { persist } from "zustand/middleware";

type AdminState = {
  adminToken: string | null;
  adminLogin: (adminSecret: string) => void;
  adminLogout: () => void;
};

type VoterState = {
  voterToken: string | null;
  voterLogin: (voterSecret: string) => void;
  voterLogout: () => void;
};

export const useAdminStore = create<AdminState>(
  persist(
    (set) => ({
      adminToken: null,
      adminLogin: (adminToken: string) => set({ adminToken }),
      adminLogout: () => set({ adminToken: null }),
    }),
    {
      name: "voting-system-admin",
    }
  )
);

export const useVoterStore = create<VoterState>(
  persist(
    (set) => ({
      voterToken: null,
      voterLogin: (voterToken: string) => set({ voterToken }),
      voterLogout: () => set({ voterToken: null }),
    }),
    {
      name: "voting-system-voter",
    }
  )
);
