import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useVoterLogin } from "../../hooks/useVoterLogin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function VoterLogin() {
  const classes = useStyles();

  const [token, setToken] = React.useState("");
  const [error, setError] = React.useState(false);
  const [showToken, setShowToken] = React.useState(false);

  const { performLogin } = useVoterLogin(setError);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performLogin(token);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5">Zu den Abstimmungen</Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="token"
            label="Dein Abstimmcode"
            type={showToken ? "text" : "password"}
            id="token"
            autoFocus
            value={token}
            onChange={(event) => {
              setToken(event.target.value);
              setError(false);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowToken(!showToken)}
                    edge="end"
                  >
                    {showToken ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Abstimmungen teilnehmen
          </Button>
        </form>
      </div>
      {error && (
        <Box mt={2}>
          <Alert severity="error">Fehler beim Einloggen!</Alert>
        </Box>
      )}
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Made by "}
          <Link color="inherit" href="mailto:mail@fabiannickel.de">
            Fabian Nickel
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default VoterLogin;
