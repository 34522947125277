import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { AllQuestionsQuery } from "../../../generated/graphql";

function AdminQuestionsOverview({
  questions,
}: {
  questions: AllQuestionsQuery["questions"];
}) {
  const activeQuestion = questions.find(
    (question) => question.state === "active"
  );

  const pendingQuestions = questions.filter(
    (question) => question.state === "pending"
  );

  const finishedQuestions = questions.filter(
    (question) => question.state === "finished"
  );

  return (
    <div>
      <Box mt={2}>
        <Typography
          component="h4"
          variant="subtitle1"
          style={{ fontWeight: 600 }}
        >
          Aktive Frage
        </Typography>
        <List component="nav" aria-label="Aktive Abstimmung">
          {activeQuestion && (
            <ListItem
              button
              dense
              component={Link}
              to={`/admin/questions/active`}
            >
              <ListItemText primary={activeQuestion.title} />
            </ListItem>
          )}
        </List>
      </Box>

      <Box mt={2}>
        <Typography
          component="h4"
          variant="subtitle1"
          style={{ fontWeight: 600 }}
        >
          Ausstehend
        </Typography>
        <List component="nav" aria-label="Aktive Frage">
          {pendingQuestions.map((question) => (
            <ListItem
              button
              dense
              key={question.id}
              component={Link}
              to={`/admin/questions/edit/${question.id}`}
            >
              <ListItemText primary={question.title} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box mt={2}>
        <Typography
          component="h4"
          variant="subtitle1"
          style={{ fontWeight: 600 }}
        >
          Abgeschlossen
        </Typography>
        <List component="nav" aria-label="Aktive Frage">
          {finishedQuestions.map((question) => (
            <ListItem
              button
              dense
              key={question.id}
              component={Link}
              to={`/admin/questions/finished/${question.id}`}
            >
              <ListItemText primary={question.title} />
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
}

export default AdminQuestionsOverview;
