import { useContext, useEffect, useState } from "react";
import { VoterClientId } from "../../../context/VoterClientId";
import { useInsertLastSeenMutation } from "../../../generated/graphql";
import { useVoterApolloClient } from "../../../hooks/useVoterApolloClient";

export const useVoterLastSeen = () => {
  const [onlineIndicator, setOnlineIndicator] = useState<NodeJS.Timeout | null>(
    null
  );
  const client = useVoterApolloClient();
  const clientId = useContext(VoterClientId);

  const [mutation] = useInsertLastSeenMutation({ client });

  const updateLastSeen = () => {
    mutation({
      variables: { clientId },
    });
  };

  useEffect(() => {
    console.log("update last seen init");
    updateLastSeen();

    setOnlineIndicator(setInterval(() => updateLastSeen(), 30000));

    return () => {
      if (onlineIndicator) {
        clearInterval(onlineIndicator);
      }
    };
  }, [client]); // eslint-disable-line
};
