import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import CardHomeLink from "../../../components/CardHomeLink/CardHomeLink";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  firstBox: {
    fontSize: "18px",
  },
}));

function AdminHome() {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Typography component="h1" variant="h5" className={classes.headline}>
        <em>ADMIN:</em> Online Abstimmung
      </Typography>
      <Paper className={classes.paper}>
        <Box m={3} className={classes.firstBox}>
          Du befindest dich im <strong>Admin Bereich</strong>.
        </Box>
      </Paper>
      <CardHomeLink
        to="/admin/users"
        title="Nutzer"
        text="Editiere die Teilnehmerliste"
        IconComponent={PeopleOutlinedIcon}
        backgroundColor="#5E0B15"
        textColor="#FFFFFF"
      />
      <CardHomeLink
        to="/admin/questions"
        title="Abstimmungen"
        text="Überblick und Hinzufügen der Fragen"
        IconComponent={CheckBoxOutlinedIcon}
        backgroundColor="#A3BAC3"
        textColor="#000000"
      />
      <CardHomeLink
        to="/admin/results"
        title="Ergebnisse"
        text="Überblick über die bisherigen Ergebnisse"
        IconComponent={AssessmentOutlinedIcon}
        backgroundColor="#01A7C2"
        textColor="#FFFFFF"
      />
    </Container>
  );
}

export default AdminHome;
