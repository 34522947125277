import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UserAppBar from "../../components/UserAppBar/UserAppBar";
import UserDrawer from "../../components/UserDrawer/UserDrawer";
import VoterLogin from "../../components/VoterLogin/VoterLogin";
import { GRAPHQL_URL, GRAPHQL_WS_URL } from "../../constants/constants";
import { VoterApolloClient } from "../../context/VoterApolloClient";
import { VoterClientId } from "../../context/VoterClientId";
import { useVoterStore } from "../../zustand";
import UserExplanation from "./UserExplanation/UserExplanation";
import UserHome from "./UserHome/UserHome";
import UserTokenExchange from "./UserTokenExchange/UserTokenExchange";
import Voting from "./Voting/Voting";

const clientId = Math.random().toString(20).slice(2);

const SecureRoute = ({
  isLoggedIn,
  path,
  children,
}: {
  isLoggedIn: boolean;
  path: string;
  children: React.ReactNode;
}) => (isLoggedIn ? <Route path={path}>{children}</Route> : <></>);

function UserIndex() {
  const match = useRouteMatch();
  const voterToken = useVoterStore((state) => state.voterToken);
  const isLoggedIn = voterToken !== null;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const voterClient = React.useMemo(() => {
    const httpLink = new HttpLink({
      uri: GRAPHQL_URL,
      headers: {
        authorization: "Bearer " + voterToken,
      },
    });

    const wsLink = new WebSocketLink({
      uri: GRAPHQL_WS_URL,
      options: {
        reconnect: true,
        connectionParams: () => ({
          headers: {
            authorization: "Bearer " + voterToken,
          },
        }),
      },
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    return new ApolloClient({
      connectToDevTools: true,
      link: splitLink,
      cache: new InMemoryCache(),
    });
  }, [voterToken]);

  return (
    <VoterApolloClient.Provider value={voterClient}>
      <VoterClientId.Provider value={clientId}>
        <UserDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        <UserAppBar setDrawerOpen={setDrawerOpen} />
        <Switch>
          <Route path={`${match.path}email-token`}>
            <UserTokenExchange />
          </Route>
          <SecureRoute path={`${match.path}voting`} isLoggedIn={isLoggedIn}>
            <Voting />
          </SecureRoute>
          <SecureRoute
            path={`${match.path}explanation`}
            isLoggedIn={isLoggedIn}
          >
            <UserExplanation />
          </SecureRoute>

          {!isLoggedIn && (
            <Route path={`${match.path}:anything`}>
              <VoterLogin />
            </Route>
          )}

          <Route path={`${match.path}`}>
            <UserHome />
          </Route>
        </Switch>
      </VoterClientId.Provider>
    </VoterApolloClient.Provider>
  );
}

export default UserIndex;
