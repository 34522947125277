import React from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useSnackbar } from "notistack";
import { useVoterStore } from "../../zustand";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function UserAppBar({
  setDrawerOpen,
}: {
  setDrawerOpen: (open: boolean) => void;
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = useVoterStore((state) => state.voterToken) !== null;
  const voterLogout = useVoterStore((state) => state.voterLogout);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            BRC Online Abstimmung
          </Typography>
          {isLoggedIn && (
            <Button
              color="inherit"
              onClick={() => {
                voterLogout();
                enqueueSnackbar("Sie wurden ausgeloggt", { variant: "info" });
              }}
            >
              Abmelden
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default UserAppBar;
