import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PrintIcon from "@material-ui/icons/Print";
import { useLocation } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { API_BASE_URL } from "../../../constants/constants";
import { useVoterLogin } from "../../../hooks/useVoterLogin";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  copyButton: {
    marginTop: theme.spacing(2),
  },
}));

function UserTokenExchange() {
  const query = new URLSearchParams(useLocation().search);
  console.log(query.get("email"));
  console.log(query.get("token"));
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [code, setCode] = React.useState<string>("");

  const { performLogin } = useVoterLogin();

  const exchangeToken = async () => {
    try {
      const result: any = await fetch(
        `${API_BASE_URL}/auth/exchange-email-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: query.get("email"),
            emailToken: query.get("token"),
          }),
        }
      );
      const resultJson = await result.json();
      const { voter_token: voterToken } = resultJson;
      console.log("voterToken", voterToken);
      if (!voterToken) {
        throw new Error("No token");
      }
      enqueueSnackbar("Abstimmungs-Code erhalten", { variant: "success" });
      setCode(voterToken);
    } catch (e) {
      enqueueSnackbar("Ein Problem is aufgetreten.", { variant: "error" });
    }
  };

  const onCodeCopy = () => {
    enqueueSnackbar("Code in Zwischenablage kopiert", { variant: "success" });
  };

  const directLogin = async () => {
    try {
      await confirm({
        description:
          "Bitte nur fortfahren, wenn du dir den Abstimmungs-Code bereits gesichert hast.",
      });
    } catch (e) {
      return;
    }
    performLogin(code, true);
  };

  return (
    <Container component="main" maxWidth="sm" className="section-to-print">
      <div className={classes.paper}>
        {!code && (
          <>
            <Typography component="h2" variant="h5" paragraph>
              Anmeldung
            </Typography>

            <Typography variant="body1" paragraph>
              Du kannst hier deinen einmalig zugesandten Email Token umtauschen
              in einen Token, mit dem du an den Abstimmungen teilnehmen kannst.
              Wenn du auf den Link in der Email geklickt hast, kannst du nun mit
              einen Klick auf "Abstimmungs-Code erhalten" einen "Stimmzettel"
              erhalten und an den Abstimmungen teilnehmen.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>Sehr wichtig: </strong> Bitte schreibe dir den Code, den
              du gleich erhälst sehr gut auf!
              <br />
              Ein verloren gegangener Abstimmungs-Code kann unter keinen
              Umständen ersetzt werden. Dieser ist gleichzusetzen mit einem
              Stimmzettel.
            </Typography>

            <Typography
              variant="body1"
              paragraph
              style={{ fontWeight: "bold" }}
            >
              Schreibe dir den Abstimmungs-Code gut und sicher auf!
            </Typography>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={exchangeToken}
            >
              Abstimmungs-Code erhalten
            </Button>
          </>
        )}
        {code && (
          <>
            <Typography component="h2" variant="h5" paragraph>
              Dein Abstimmungs-Code
            </Typography>

            <Typography variant="h4" paragraph>
              Der folgende Code ist dein <strong>Abstimmungs-Code</strong>.
              Bitte bewahre diesen gut auf!
            </Typography>

            <Box mt={5} mb={5}>
              <Paper>
                <Box p={3} style={{ maxWidth: "370px", textAlign: "center" }}>
                  <Typography component="p" variant="h5">
                    {code}
                  </Typography>
                  <div className="hide-print">
                    <CopyToClipboard text={code} onCopy={onCodeCopy}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.copyButton}
                        startIcon={<FileCopyIcon />}
                      >
                        In Zwischenablage
                      </Button>
                    </CopyToClipboard>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.copyButton}
                      startIcon={<PrintIcon />}
                      onClick={() => {
                        window.print();
                      }}
                    >
                      Drucken
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      color="default"
                      className={classes.copyButton}
                      startIcon={<ExitToAppIcon />}
                      onClick={directLogin}
                    >
                      Direkt Anmelden
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Box>

            <Typography variant="body1" paragraph>
              Du kannst dir diesen beispielsweise auf einen Zettel notieren oder
              in einem Passwort Manager speichern. Mit diesem Code kannst du an
              den Abstimmungen teilnehmen.
            </Typography>
          </>
        )}
      </div>
    </Container>
  );
}

export default UserTokenExchange;
