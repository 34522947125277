import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useSnackbar } from "notistack";
import { useAdminStore } from "../../zustand";
import { API_BASE_URL } from "../../constants/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AdminLogin() {
  const classes = useStyles();
  const adminLogin = useAdminStore((state) => state.adminLogin);

  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const performLogin = async () => {
    try {
      const result = await fetch(`${API_BASE_URL}/auth/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adminSecret: password,
        }),
      });
      const resultJson = await result.json();
      const { token } = resultJson;
      if (!token) {
        throw new Error("No valid token received");
      }
      console.log("token", token);
      localStorage.setItem("token", token);
      adminLogin(token);
      enqueueSnackbar("Erfolgreich eingeloggt", { variant: "success" });
    } catch (e) {
      setError(true);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    performLogin();
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h5">Login</Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Admin Name"
            name="username"
            defaultValue="admin"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
              setError(false);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // disabled={status === "loading"}
            // onClick={() => refetch()}
          >
            Einloggen
          </Button>
        </form>
      </div>
      {error && (
        <Box mt={2}>
          <Alert severity="error">Fehler beim Einloggen!</Alert>
        </Box>
      )}
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Made by "}
          <Link color="inherit" href="mailto:mail@fabiannickel.de">
            Fabian Nickel
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default AdminLogin;
