import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import React from "react";
import {
  useInsertVoteMutation,
  useSubscribeQuestionsSubscription,
} from "../../../generated/graphql";
import { useVoterApolloClient } from "../../../hooks/useVoterApolloClient";
import { useSnackbar } from "notistack";
import { useVoterLastSeen } from "./useVoterLastSeen";

const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  paragraphDistance: {
    marginTop: theme.spacing(3),
  },
  voteTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(3),
  },
  voteQuestion: {
    fontSize: theme.typography.h5.fontSize,
  },
  table: {
    "& .MuiTableCell-root": {
      fontSize: "1.1rem",
    },
  },
  tableCheckbox: {
    backgroundColor: theme.palette.grey[300],
  },
  iconDiv: {
    fontSize: "2rem !important", // quick fix, overwrite the one above
    position: "relative",
    "& svg": {
      fill: theme.palette.secondary.dark,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  voteButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },
  },
  voteCheckbox: {
    color: green[400],
    "&$checked": {
      color: green[800],
    },
  },
  voteCheckboxChecked: {},
}));

function Voting() {
  const client = useVoterApolloClient();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = React.useState<String | null>(null);
  const [voteCheckedIn, setVoteCheckedIn] = React.useState(false);

  useVoterLastSeen();

  const { data, loading } = useSubscribeQuestionsSubscription({ client });
  const question = data?.questions[0];

  const [mutationVote] = useInsertVoteMutation({ client });

  const votingActive = (data?.questions.length || 0) > 0;
  const hasVoted = votingActive && (question?.votings.length || 0) > 0;

  const saveVote = () => {
    if (!selected) {
      return;
    }

    if (!question?.id) {
      return;
    }

    try {
      mutationVote({
        variables: {
          question_id: question?.id,
          votingValueId: selected,
        },
      });
      setSelected(null);
      setVoteCheckedIn(false);
      enqueueSnackbar("Stimme abgegeben!", { variant: "success" });
    } catch (e) {
      console.error(e);

      enqueueSnackbar("Fehler bei der Abstimmung!", { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Typography component="h1" variant="h5" className={classes.headline}>
          Geheime Online Abstimmung
        </Typography>
        <Paper className={classes.paper}>
          <Box m={3} textAlign="center">
            Umfrage wird geladen.
          </Box>
          <Box m={3} textAlign="center">
            <CircularProgress />
          </Box>
        </Paper>
      </Container>
    );
  }
  return (
    <Container maxWidth="md">
      <Typography component="h1" variant="h5" className={classes.headline}>
        Geheime Online Abstimmung
      </Typography>
      <Paper className={classes.paper}>
        <Box m={3}>
          {(!votingActive || !question) && (
            <>
              <Alert severity="info">
                Aktuell findet keine Abstimmung statt.
              </Alert>

              <Typography variant="body1" className={classes.paragraphDistance}>
                Du kannst hier warten und wir zeigen die Abstimmung an, sobald
                diese verfügbar ein.
              </Typography>
            </>
          )}
          {votingActive && hasVoted && (
            <>
              <Alert severity="info">
                Du hast für die aktuell laufende Abstimmung bereits abgestimmt.
              </Alert>

              <Typography
                variant="h5"
                className={classes.paragraphDistance}
                paragraph
              >
                Aktuelle Abstimmung:
              </Typography>

              <Typography variant="h5" className={classes.voteTitle} paragraph>
                {question?.title}
              </Typography>
              <Typography
                variant="body1"
                className={classes.voteQuestion}
                paragraph
              >
                {question?.question}
              </Typography>
              <Divider />
              <Typography
                variant="body1"
                className={classes.voteQuestion}
                paragraph
              >
                Antwortmöglichkeiten:
              </Typography>
              {question?.voting_values.map((option, index) => (
                <Box mb={2} display="flex" key={option.id}>
                  <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {index + 1}.
                  </span>
                  {option.label}
                </Box>
              ))}
            </>
          )}

          {votingActive && !hasVoted && !voteCheckedIn && (
            <>
              <Typography
                variant="h5"
                className={classes.paragraphDistance}
                paragraph
              >
                Aktuelle Abstimmung:
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraphDistance}
                paragraph
              >
                <em>
                  Du hast eine Stimme für die Abstimmung. Bitte stimme mit
                  Ja/Nein/Enthaltung ab.
                </em>
              </Typography>
              <Typography variant="h5" className={classes.voteTitle} paragraph>
                {question?.title}
              </Typography>
              <Typography
                variant="body1"
                className={classes.voteQuestion}
                paragraph
              >
                {question?.question}
              </Typography>

              <TableContainer component={Paper}>
                <Table aria-label="Abstimmung">
                  <TableBody
                    className={classes.table}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {question?.voting_values.map((option) => (
                      <TableRow
                        key={option.id}
                        hover
                        role="checkbox"
                        onClick={() => setSelected(option.id)}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.tableCheckbox}
                        >
                          <Checkbox
                            checked={selected === option.id}
                            onChange={() => setSelected(option.id)}
                            color="default"
                            classes={{
                              root: classes.voteCheckbox,
                              checked: classes.voteCheckboxChecked,
                            }}
                          />
                        </TableCell>
                        <TableCell>{option.label}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={3} display="flex">
                <Box flex={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.voteButton,
                    }}
                    disabled={selected === null}
                    onClick={() => setVoteCheckedIn(true)}
                  >
                    Abstimmen
                  </Button>
                </Box>
                <Box flex={0}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setSelected(null)}
                  >
                    Zurücksetzen
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {votingActive && !hasVoted && voteCheckedIn && (
            <>
              <Typography
                variant="h5"
                className={classes.paragraphDistance}
                paragraph
              >
                Aktuelle Abstimmung:
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraphDistance}
                paragraph
              >
                <em>
                  Du hast eine Stimme für die Abstimmung. Bitte stimme mit
                  Ja/Nein/Enthaltung ab.
                </em>
              </Typography>
              <Typography variant="h5" className={classes.voteTitle} paragraph>
                {question?.title}
              </Typography>
              <Typography
                variant="body1"
                className={classes.voteQuestion}
                paragraph
              >
                {question?.question}
              </Typography>

              <TableContainer component={Paper}>
                <Table aria-label="Abstimmung">
                  <TableBody className={classes.table}>
                    {question?.voting_values.map((option) => (
                      <TableRow key={option.id}>
                        <TableCell padding="checkbox">
                          {selected === option.id && (
                            <div className={classes.iconDiv}>
                              <CheckIcon fontSize="inherit" />
                            </div>
                          )}
                        </TableCell>
                        <TableCell>{option.label}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={3}>
                <strong>
                  Bitte überprüfe deine Stimme noch einmal und gib diese dann
                  final ab.
                </strong>
              </Box>
              <Box mt={3} display="flex">
                <Box flex={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.voteButton,
                    }}
                    onClick={saveVote}
                  >
                    Abstimmen
                  </Button>
                </Box>
                <Box flex={0}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setVoteCheckedIn(false)}
                  >
                    Abbruch
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default Voting;
