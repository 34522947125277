import { Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import HomeIcon from "@material-ui/icons/Home";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function UserDrawer({
  toggleDrawer,
  drawerOpen,
}: {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  drawerOpen: boolean;
}) {
  const classes = useStyles();

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Übersicht" />
          </ListItem>

          <ListItem button component={Link} to="/voting">
            <ListItemIcon>
              <CheckBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Abstimmungen" />
          </ListItem>

          <ListItem button component={Link} to="/explanation">
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText primary="Erklärungen" />
          </ListItem>

          {/* <ListItem button>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Ergebnisse" />
          </ListItem> */}

          <ListItem button>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Impressum" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default UserDrawer;
