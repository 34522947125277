import React from "react";
import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import AdminIndex from "./pages/admin/AdminIndex";
import UserIndex from "./pages/user/UserIndex";
// 0C4E9F // blau
// E4031A // rot

const theme = createMuiTheme({
  palette: {
    primary: { main: "#0C4E9F" },
    secondary: { main: "#E4031A" },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider
        defaultOptions={{
          title: "Bitte bestätigen",
          cancellationText: "Abbrechen",
          confirmationText: "Okay",
        }}
      >
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Router>
            <Switch>
              <Route path="/admin">
                <AdminIndex />
              </Route>
              <Route path="/">
                <UserIndex />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
